import { Hospital } from './hospital';

export class HospitalNetwork {
  public id: string;
  public name: string;

  static createFromParseObject(object: any) {
    const result = new HospitalNetwork();
    result.id = object.id;
    result.name = object.get('name');

    return result;
  }
}
