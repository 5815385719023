import * as tslib_1 from "tslib";
import { ModalController } from '@ionic/angular';
import { version } from '../../../../package.json';
import { Plugins } from '@capacitor/core';
var Browser = Plugins.Browser;
var SupportPage = /** @class */ (function () {
    function SupportPage(modalController) {
        this.modalController = modalController;
        this.appVersionNumber = version;
    }
    SupportPage.prototype.dismissModal = function () {
        this.modalController.dismiss();
    };
    SupportPage.prototype.callForSupport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // if (this.platform.is('cordova')) {
                    //   this.callNumber
                    //     .callNumber('+15873346657', true)
                    //     .then(res => console.log('Launched dialer!', res))
                    //     .catch(err => console.log('Error launching dialer', err));
                    // } else {
                    return [4 /*yield*/, Browser.open({ url: 'tel:+18322058938' })];
                    case 1:
                        // if (this.platform.is('cordova')) {
                        //   this.callNumber
                        //     .callNumber('+15873346657', true)
                        //     .then(res => console.log('Launched dialer!', res))
                        //     .catch(err => console.log('Error launching dialer', err));
                        // } else {
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SupportPage.prototype.emailForSupport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var to, subject, body;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        to = 'support@kavahealth.com';
                        subject = 'Support';
                        body = 'Help needed';
                        // if (this.platform.is('cordova')) {
                        //   this.composeEmail(to, subject, body);
                        // } else {
                        return [4 /*yield*/, Browser.open({
                                url: 
                                // tslint:disable-next-line: max-line-length
                                "mailto:" + to + "?subject=" + subject + "&body=" + body
                            })];
                    case 1:
                        // if (this.platform.is('cordova')) {
                        //   this.composeEmail(to, subject, body);
                        // } else {
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return SupportPage;
}());
export { SupportPage };
