import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pwa-install-pop-over',
  templateUrl: './pwa-install-pop-over.component.html',
  styleUrls: ['./pwa-install-pop-over.component.scss'],
})
export class PwaInstallPopOverComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
