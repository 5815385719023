<ion-content class="ion-padding ">

  <ion-button fill="clear" class="close-btn" color="dark" (click)="dismissModal(false)">
    <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
  </ion-button>


  <div class="ion-text-center">


    <img src="../../../assets/images/circle_checked.png" />
    <h1>You're all set!</h1>

    <p>ER resevation details are below:</p>
  </div>
  <ion-list>

    <ion-item>
      <ion-label class="prompt-label">Client Name:</ion-label>
      <ion-label slot="end" class="ion-text-end">{{ appointment.client?.fullName }}</ion-label>
    </ion-item>
    <ion-item>
      <ion-label class="prompt-label">Facility Name:</ion-label>
      <ion-label slot="end" class="ion-text-end">{{ appointment.hospital?.name }}</ion-label>
    </ion-item>

    <ion-item>
      <ion-label class="prompt-label">Address:</ion-label>
      <ion-label slot="end" class="ion-text-end ion-text-wrap">{{ appointment.hospital?.address }}</ion-label>
    </ion-item>

  </ion-list>

  <ion-row>

    <ion-col>
      <ion-button fill="outline" expand="block" (click)="dismissModal(true)">MAKE NEW RESERVATION</ion-button>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <ion-button fill="solid" expand="block" (click)="dismissModal(false)">
        <strong>GO TO MY APPOINTMENTS</strong>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-content>

<ion-footer>

</ion-footer>