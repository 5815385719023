import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Platform, ModalController, ToastController } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { version } from '../../package.json';
import { AuthProvider } from './services/auth/auth.js';
import { distinctUntilChanged } from 'rxjs/operators';
import { ParseProvider } from './services/parse/parse.js';
import { SplitPane } from './split-pane.js';
import { AppointmentNewComponent } from './components/appointment-new/appointment-new.component.js';
import { AppointmentService } from './services/appointment/appointment.service.js';
import * as samever from 'semver';
import { environment } from 'src/environments/environment';
import { UpdateService } from './update-service.js';
var AppComponent = /** @class */ (function () {
    function AppComponent(authService, parseProvider, splitPane, modalController, appointmentProvider, platform, toastController, update) {
        this.authService = authService;
        this.parseProvider = parseProvider;
        this.splitPane = splitPane;
        this.modalController = modalController;
        this.appointmentProvider = appointmentProvider;
        this.platform = platform;
        this.toastController = toastController;
        this.update = update;
        this.appVersionNumber = version;
        this.isProd = environment.production;
        this.newVersionAvailable = false;
        this.appointmentPage = {
            title: 'Appointments',
            url: '/tabs/appointments',
            icon: 'bookmark'
        };
        this.profilePage = {
            title: 'Profile',
            url: '/tabs/profile',
            icon: 'person'
        };
        this.supportPage = {
            title: 'Support',
            url: '/tabs/support',
            icon: 'help'
        };
        this.termsPage = {
            title: 'Terms and Conditions',
            url: '/terms',
            icon: 'book'
        };
        this.newUsersPage = {
            title: 'New Users',
            url: '/tabs/users',
            icon: 'flash'
        };
        this.hospitalsPage = {
            title: 'Providers',
            url: '/tabs/hospitals',
            icon: 'business'
        };
        this.userListPage = {
            title: 'User List',
            url: '/tabs/user-list',
            icon: 'contacts'
        };
        this.initializeApp();
    }
    AppComponent.prototype.initializeApp = function () {
        var _this = this;
        this.platform.ready().then(function () {
            // this.statusBar.styleDefault();
            // this.splashScreen.hide();
            _this.checkVersion();
            _this.update.checkForUpdate();
        });
    };
    Object.defineProperty(AppComponent.prototype, "username", {
        get: function () {
            return this.authService.currentUser() ? this.authService.currentUser().firstName + " " + this.authService.currentUser().lastName : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isLawFirmUser", {
        get: function () {
            return this.authService.currentUser() ? this.authService.currentUser().lawFirmId != null : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "userPages", {
        get: function () {
            if (this.authService.currentUser()) {
                if (this.authService.currentUser().isKavaUser) {
                    return [this.newUsersPage, this.appointmentPage, this.hospitalsPage, this.userListPage];
                }
                return [this.appointmentPage];
            }
            return [];
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.ngOnInit = function () {
        console.log('app start');
        this.authSub = this.authService.userState$
            .pipe(distinctUntilChanged())
            .subscribe(function (userState) {
            console.log('is user authenticated?', userState);
            // todo: this subscriber doesn't get update?
            // if (userState == null) {
            //   this.appPages = [];
            // } else {
            //   this.appPages = [this.appointmentPage, this.profilePage];
            //   if (userState.isKavaUser) {
            //     this.appPages = [this.newUsersPage, this.hospitalsPage, ...this.appPages];
            //   }
            // }
        });
    };
    AppComponent.prototype.onScheduleClient = function () {
        this.presentNewAppointmentModal();
    };
    AppComponent.prototype.presentNewAppointmentModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.appointmentProvider.setToUpcomingMode();
                        return [4 /*yield*/, this.modalController.create({
                                component: AppointmentNewComponent,
                                componentProps: {}
                            })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (res) {
                            if (res.data && res.data.success) {
                                // this.listAppointments();
                                // this.presentAppointmentConfirmationModal(res.data.result);
                            }
                        });
                        return [4 /*yield*/, modal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AppComponent.prototype.checkVersion = function () {
        var _this = this;
        this.parseProvider.getConfig('latestVersion').then(function (ver) {
            console.log('latest ver:', ver, 'current ver:', _this.appVersionNumber);
            if (samever.gt(ver, _this.appVersionNumber)) {
                console.log('poping new version alert');
                _this.newVersionAvailable = true;
                _this.presentToast("New version " + ver + " is available, please refresh browser and wait a few seconds.");
            }
        });
    };
    AppComponent.prototype.reloadPage = function () {
        window.location.reload();
    };
    AppComponent.prototype.presentToast = function (message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastController.create({
                            message: message,
                            duration: 5000,
                            position: 'bottom',
                            color: 'danger'
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.ngOnDestroy = function () {
        if (this.authSub) {
            this.authSub.unsubscribe();
        }
        // Plugins.App.removeListener('appStateChange', this.checkAuthOnResume);
    };
    return AppComponent;
}());
export { AppComponent };
