import { HospitalNetwork } from './hospital-network';

export class Hospital {
  public id: string;
  public name: string;
  public street: string;
  public city: string;
  public state: string;

  public address: string;
  public zipCode: string;
  public distance?: number;
  public phoneNumber: string;
  public network?: HospitalNetwork;
  public networkId?: string;

  static createFromParseObject(object: any) {
    const result = new Hospital();
    result.id = object.id;
    result.name = object.get('name');
    result.street = object.get('street');
    result.zipCode = object.get('zip');
    result.city = object.get('city');
    result.state = object.get('state');
    result.phoneNumber = object.get('phoneNumber');
    result.address = `${object.get('street')} ${object.get(
      'city'
    )}, ${object.get('state')} ${object.get('zip')}`;
    // TODO: find network from relationship?
    const network = object.get('network');
    if (network) {
      result.networkId = network.id;
      result.network = HospitalNetwork.createFromParseObject(
        network
      );
    }

    return result;
  }
}
