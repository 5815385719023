import { ParseProvider } from '../parse/parse';
import { Parse } from 'parse';
import { Hospital } from 'src/app/model/hospital';
import * as i0 from "@angular/core";
import * as i1 from "../parse/parse";
var HospitalService = /** @class */ (function () {
    function HospitalService(parseProvider) {
        this.parseProvider = parseProvider;
    }
    HospitalService.prototype.getHospitals = function () {
        var _this = this;
        if (this.cachedHospitals) {
            return new Promise(function (resolve, reject) { return resolve(_this.cachedHospitals); });
        }
        return this.parseProvider.getObjects('Provider', 0, 1000, function (query) {
            query.ascending('name');
            query.notEqualTo('isDeactivated', true);
            query.include('network');
            return query;
        }).then(function (res) {
            _this.cachedHospitals = res.results.map(function (x) { return Hospital.createFromParseObject(x); });
            _this.totalCount = res.totalCount;
            return _this.cachedHospitals;
        });
    };
    HospitalService.prototype.sortHospitalsByDistanceTo = function (clientZipCode) {
        var hospitalZipCodes = this.cachedHospitals.map(function (x) { return x.zipCode; });
        return Parse.Cloud.run('sortZipCodsByDistance', { clientZipCode: clientZipCode, hospitalZipCodes: hospitalZipCodes })
            .then(function (res) {
            return res.data.distances;
            // const distances = res.data.distances;
            // const list = [];
            // for (const zip in distances) {
            //   list.push({ zip, distance: distances[zip] });
            // }
            // return list.sort((a, b) => a.distance - b.distance);
        });
    };
    HospitalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HospitalService_Factory() { return new HospitalService(i0.ɵɵinject(i1.ParseProvider)); }, token: HospitalService, providedIn: "root" });
    return HospitalService;
}());
export { HospitalService };
