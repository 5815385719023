import { Component, OnInit } from '@angular/core';
import { AuthProvider } from 'src/app/services/auth/auth';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.page.html',
  styleUrls: ['./terms.page.scss'],
})
export class TermsPage implements OnInit {

  constructor(public authProvider: AuthProvider) { }

  ngOnInit() {
  }

}
