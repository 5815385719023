<ion-row>
  <ion-col size="2" class="ion-align-self-center">
    <img src="../../../assets/icon/icons8-add-new-100.png" style=" width: 30px;" />
  </ion-col>
  <ion-col>
    <p>
      Install this webapp on your iOS device: tap
      <img src="../../../assets/icon/icons8-upload-50.png" style="height: 22px; width: 22px;" /> and then Add to Home Screen.
    </p>
  </ion-col>
</ion-row>