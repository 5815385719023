import { Injectable } from '@angular/core';
import { ParseProvider } from '../parse/parse';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Appointment,
  AppointmentListResponse,
  AppointmentsDisplayMode
} from 'src/app/model/appointment';
import { AuthProvider, User } from '../auth/auth';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  cachedUpcomingList = [];
  cachedRecentList = [];

  totalUpcomingCount = -1;
  totalRecentCount = -1;
  totalUpcomingSearchResultCount = 0;
  totalRecentSearchResultCount = 0;
  searchKeyword = '';
  currentUser: User;

  private upcomingListSubject = new BehaviorSubject<AppointmentListResponse>(
    new AppointmentListResponse()
  );

  private recentListSubject = new BehaviorSubject<AppointmentListResponse>(
    new AppointmentListResponse()
  );

  private displayMode = new BehaviorSubject<AppointmentsDisplayMode>(
    AppointmentsDisplayMode.Upcoming
  );

  public get upcomingAppointments() {
    return this.upcomingListSubject.asObservable();
  }

  public get recentAppointments() {
    return this.recentListSubject.asObservable();
  }

  public get appontmentListDisplayMode() {
    return this.displayMode.asObservable();
  }

  setToUpcomingMode() {
    this.displayMode.next(AppointmentsDisplayMode.Upcoming);
  }

  constructor(
    private parseProvider: ParseProvider,
    private authProvider: AuthProvider,
    private router: Router
  ) {
    this.authProvider.currentUser$().subscribe(user => {
      this.currentUser = user;
      if (user == null) {
        this.resetData(true);
      }
    });
  }

  resetData(resetTotalCounts = false) {
    this.cachedUpcomingList = [];
    this.cachedRecentList = [];

    if (resetTotalCounts) {
      // when search keyword changes, no need to reset those values.
      this.totalUpcomingCount = -1;
      this.totalRecentCount = -1;
    }

    this.totalUpcomingSearchResultCount = 0;
    this.totalRecentSearchResultCount = 0;
    this.searchKeyword = '';

    this.upcomingListSubject.next(new AppointmentListResponse());
    this.recentListSubject.next(new AppointmentListResponse());
  }

  loadData(
    searchKeyword = null,
    offset = 0,
    isUpcomingMode = true,
    limit: number = 20
  ): Promise<any> {
    return this.parseProvider
      .getAppointments(
        this.currentUser,
        isUpcomingMode,
        searchKeyword,
        offset,
        limit
      )
      .then(
        response => {
          if (isUpcomingMode) {
            this.setToUpcomingMode();
          }


          this.searchKeyword = searchKeyword || '';


          if (isUpcomingMode) {
            if (this.searchKeyword.length === 0) {
              this.totalUpcomingCount = response.totalCount;
            } else {
              this.totalUpcomingSearchResultCount = response.totalCount;
            }
            if (offset === 0) {
              this.cachedUpcomingList = [];
            }
            this.cachedUpcomingList = [
              ...this.cachedUpcomingList,
              ...response.results.map(x => Appointment.createFromParseObject(x))
            ];
            const res = new AppointmentListResponse(
              response.totalCount,
              this.cachedUpcomingList
            );
            this.upcomingListSubject.next(res);
            return res;
          } else {
            if (this.searchKeyword.length === 0) {
              this.totalRecentCount = response.totalCount;
            } else {
              this.totalRecentSearchResultCount =
                response.totalCountOfAppointmentsToDisplay;
            }
            if (offset === 0) {
              this.cachedRecentList = [];
            }
            this.cachedRecentList = [
              ...this.cachedRecentList,
              ...response.results.map(x => Appointment.createFromParseObject(x))
            ];
            const res = new AppointmentListResponse(
              response.totalCount,
              this.cachedRecentList
            );
            this.recentListSubject.next(res);
            return res;
          }
        },
        err => {
          console.error('listappointments err', err);
          if (err.code === 209) {
            // 209 Invalid session token
            this.authProvider.signout().subscribe(res => {
              console.log('signout', res);
            });

            this.router.navigate(['/signin']);
          }

          return new AppointmentListResponse(
            0,
            []
          );
        }
      );
  }


  checkIn(appointment) {
    return this.parseProvider.checkinAppointment(appointment).then((res) => {

      const appointmentUpdated = Appointment.createFromParseObject(res);
      console.log('appointment checked in', appointmentUpdated);

      this.cachedRecentList = [appointmentUpdated, ...this.cachedRecentList];
      this.recentListSubject.next(new AppointmentListResponse(
        this.cachedRecentList.length,
        this.cachedRecentList
      ));
      this.cachedUpcomingList = this.cachedUpcomingList.filter(x => x.id !== appointmentUpdated.id);
      this.upcomingListSubject.next(new AppointmentListResponse(
        this.cachedUpcomingList.length,
        this.cachedUpcomingList
      ));
    });
  }

  resendReminder(appointment) {
    return this.parseProvider.resendReminder(appointment);
  }

  cancel(appointment) {
    return this.parseProvider.cancelAppointment(appointment).then((res) => {

      const appointmentUpdated = Appointment.createFromParseObject(res);
      console.log('appointment cancelled', appointmentUpdated);

      this.cachedRecentList = [appointmentUpdated, ...this.cachedRecentList];
      this.recentListSubject.next(new AppointmentListResponse(
        this.cachedRecentList.length,
        this.cachedRecentList
      ));
      this.cachedUpcomingList = this.cachedUpcomingList.filter(x => x.id !== appointmentUpdated.id);
      this.upcomingListSubject.next(new AppointmentListResponse(
        this.cachedUpcomingList.length,
        this.cachedUpcomingList
      ));
    });
  }

  update(appointment) {
    return this.parseProvider.updateAppointment(appointment).then(res => {

      const index = this.cachedUpcomingList.indexOf(x => x.id === appointment.id);
      if (index > -1) {
        this.cachedUpcomingList = Object.assign([], this.cachedUpcomingList, { indexRecent: appointment });
        this.upcomingListSubject.next(new AppointmentListResponse(
          this.cachedUpcomingList.length,
          this.cachedUpcomingList
        ));
      }
    });
  }
}
