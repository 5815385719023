import * as libphonenumber from 'google-libphonenumber';
import * as i0 from "@angular/core";
var PhoneService = /** @class */ (function () {
    function PhoneService() {
    }
    PhoneService.prototype.isValidNumber = function (phoneNumber) {
        var phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
        try {
            var parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, 'US');
            if (phoneUtil.isPossibleNumber(parsedNumber)) {
                return phoneUtil.isValidNumber(parsedNumber);
            }
            return false;
        }
        catch (err) {
            // console.error(err);
            return false;
        }
    };
    PhoneService.prototype.format = function (phoneNumber) {
        var phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
        return phoneUtil.formatInOriginalFormat(phoneNumber, 'US');
    };
    PhoneService.prototype.noExcepetion = function (func) {
        var ret;
        try {
            ret = func();
        }
        catch (err) {
            ret = 'ERROR';
        }
        finally {
        }
    };
    PhoneService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PhoneService_Factory() { return new PhoneService(); }, token: PhoneService, providedIn: "root" });
    return PhoneService;
}());
export { PhoneService };
