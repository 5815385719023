import { Component, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { version } from '../../../../package.json';
import { Plugins } from '@capacitor/core';
const { Browser } = Plugins;
@Component({
  selector: 'app-support',
  templateUrl: 'support.page.html',
  styleUrls: ['support.page.scss']
})
export class SupportPage {
  appVersionNumber: string = version;

  @Input() isModalMode: false;

  constructor(public modalController: ModalController) {}

  dismissModal() {
    this.modalController.dismiss();
  }

  async callForSupport() {
    // if (this.platform.is('cordova')) {
    //   this.callNumber
    //     .callNumber('+15873346657', true)
    //     .then(res => console.log('Launched dialer!', res))
    //     .catch(err => console.log('Error launching dialer', err));
    // } else {
    await Browser.open({ url: 'tel:+18322058938' });
    // }
  }

  async emailForSupport() {
    const to = 'support@kavahealth.com';
    const subject = 'Support';
    const body = 'Help needed';

    // if (this.platform.is('cordova')) {
    //   this.composeEmail(to, subject, body);
    // } else {
    await Browser.open({
      url:
        // tslint:disable-next-line: max-line-length
        `mailto:${to}?subject=${subject}&body=${body}`
    });
    // }
  }
}
