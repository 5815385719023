// Parse
import { Parse } from 'parse';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { AuthProvider } from '../auth/auth';
import { AppointmentStatus, LawFirm } from '../../model/appointment';
import { Router } from '@angular/router';
import { HospitalNetwork } from 'src/app/model/hospital-network';
import * as i0 from "@angular/core";
import * as i1 from "../auth/auth";
import * as i2 from "@angular/router";
// Constants
var ListResponse = /** @class */ (function () {
    function ListResponse() {
    }
    return ListResponse;
}());
export { ListResponse };
var ParseProvider = /** @class */ (function () {
    function ParseProvider(authProvider, router) {
        this.authProvider = authProvider;
        this.router = router;
        this.parseAppId = environment.parseAppId;
        this.parseJsKey = environment.parseJsKey;
        this.parseServerUrl = environment.parseServerUrl;
        this.parseInitialize();
        this.getProviderNetworks();
        this.getLawForms();
    }
    ParseProvider.prototype.uniqueObjects = function (array) {
        var result = [];
        var check = new Map();
        for (var _i = 0, array_1 = array; _i < array_1.length; _i++) {
            var item = array_1[_i];
            if (!check.has(item.id)) {
                check.set(item.id, true); // set any value to Map
                result.push(item);
            }
        }
        return result.sort(function (a, b) { return (a.name < b.name ? -1 : 1); });
    };
    ParseProvider.prototype.getConfig = function (configName) {
        return Parse.Config.get().then(function (config) {
            // console.log(config.get(configName));
            return config.get(configName);
        });
    };
    ParseProvider.prototype.getObjects = function (className, offset, limit, queryFilters) {
        var _this = this;
        if (offset === void 0) { offset = 0; }
        if (limit === void 0) { limit = 40; }
        if (queryFilters === void 0) { queryFilters = null; }
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                var ObjectType = Parse.Object.extend(className);
                var query = new Parse.Query(ObjectType);
                if (queryFilters) {
                    query = queryFilters(query);
                }
                query.count().then(function (totalCount) {
                    // todo: check offset with total count of object.
                    query.skip(offset);
                    query.limit(limit);
                    console.log('run getObjects querying on ', className);
                    query.find().then(function (results) {
                        resolve({ totalCount: totalCount, results: results });
                    }, function (error) {
                        console.error(error);
                        reject(error);
                    });
                }, function (err) {
                    // Unauthrized error, token exired.
                    // logout.
                    if (err.message === 'unauthorized' || err.code === 209) {
                        console.log('logging out');
                        _this.authProvider.signout().subscribe();
                        _this.router.navigate(['/signin']);
                    }
                });
            }, 0);
        });
    };
    ParseProvider.prototype.findObject = function (className, id, includeKeys) {
        if (includeKeys === void 0) { includeKeys = null; }
        var query = new Parse.Query(Parse.Object.extend(className));
        query.equalTo('objectId', id);
        if (includeKeys) {
            query.include(includeKeys);
        }
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                query.first().then(function (result) {
                    resolve(result);
                }, function (error) {
                    console.error(error);
                    reject(error);
                });
            }, 0);
        });
    };
    ParseProvider.prototype.parseInitialize = function () {
        Parse.initialize(this.parseAppId, this.parseJsKey);
        Parse.serverURL = this.parseServerUrl;
    };
    /*
  
  
    */
    ParseProvider.prototype.findUser = function (id) {
        return this.findObject(Parse.User, id, ['providerNetwork']);
    };
    ParseProvider.prototype.findAppointment = function (id) {
        return this.findObject('Appointment', id, ['client', 'hospital']);
    };
    ParseProvider.prototype.findHospital = function (id) {
        return this.findObject('Provider', id, ['network']);
    };
    ParseProvider.prototype.findLawFirm = function (id) {
        return this.findObject('LawFirm', id, []);
    };
    ParseProvider.prototype.findLawFirmGroup = function (id) {
        return this.findObject('ManagingAggregator', id, []);
    };
    ParseProvider.prototype.findLawFirmGroupMembers = function (lawFirmGroupObject) {
        return lawFirmGroupObject.relation('client_firm').query();
    };
    ParseProvider.prototype.findClient = function (id) {
        return this.findObject('Client', id, []);
    };
    ParseProvider.prototype.findUserByPhoneNumber = function (phone) {
        return this.getObjects(Parse.User, 0, 20, function (query) {
            query.equalTo('phone', phone);
            query.ascending('createdAt');
            return query;
        }).then(function (res) { return res.results[0]; });
    };
    ParseProvider.prototype.findSessionByUser = function (user) {
        return this.getObjects('_Session', 0, 1, function (query) {
            query.equalTo('user', user);
            query.ascending('createdAt');
            return query;
        }).then(function (res) { return res.results[0]; });
    };
    // TODO: move saved data to user table. no need to run query on huge appointment list anymore.
    ParseProvider.prototype.getRecentHospitals = function (userContext, limit) {
        var _this = this;
        if (limit === void 0) { limit = 20; }
        return this.getObjects('Appointment', 0, limit, function (query) {
            var userQuery = new Parse.Query(Parse.User);
            userQuery.equalTo('lastName', userContext.lastName);
            // query.equalTo('createdBy', {
            //   __type: 'Pointer',
            //   className: '_User',
            //   objectId: userContext.id
            // });
            query.matchesQuery('createdBy', userQuery);
            query.descending('createdAt');
            query.include('hospital');
            return query;
        }).then(function (res) {
            var list = res.results.map(function (x) { return x.get('hospital'); });
            var set = _this.uniqueObjects(list);
            var recent = set.slice(0, 3);
            return new Promise(function (resolve, reject) { return resolve(recent); });
        });
    };
    ParseProvider.prototype.buildQueryMatchesKeyword = function (className, keyName, keyword) {
        var query = new Parse.Query(Parse.Object.extend(className));
        query.matches(keyName, keyword.trim(), 'i');
        return query;
    };
    ParseProvider.prototype.getUpcomingAppointments = function (userContext, searchKeyword, offset, limit) {
        if (searchKeyword === void 0) { searchKeyword = null; }
        if (offset === void 0) { offset = 0; }
        if (limit === void 0) { limit = 20; }
        return this.getAppointments(userContext, true, searchKeyword, offset, limit);
    };
    ParseProvider.prototype.getRecentAppointments = function (userContext, searchKeyword, offset, limit) {
        if (searchKeyword === void 0) { searchKeyword = null; }
        if (offset === void 0) { offset = 0; }
        if (limit === void 0) { limit = 20; }
        return this.getAppointments(userContext, false, searchKeyword, offset, limit);
    };
    ParseProvider.prototype.getAppointments = function (userContext, upcoming, searchKeyword, offset, limit) {
        var _this = this;
        if (upcoming === void 0) { upcoming = true; }
        if (searchKeyword === void 0) { searchKeyword = null; }
        if (offset === void 0) { offset = 0; }
        if (limit === void 0) { limit = 20; }
        return this.getObjects('Appointment', offset, limit, function (query) {
            var _a;
            query.include(['client', 'hospital', 'lawFirm']);
            if (userContext == null) {
                query.equalTo('objectId', null);
                return query;
            }
            if (searchKeyword && searchKeyword.trim().length > 0) {
                var queries = [
                    _this.buildQueryMatchesKeyword('Client', 'firstName', searchKeyword),
                    _this.buildQueryMatchesKeyword('Client', 'middleName', searchKeyword),
                    _this.buildQueryMatchesKeyword('Client', 'lastName', searchKeyword),
                    _this.buildQueryMatchesKeyword('Client', 'phone', searchKeyword)
                ];
                query.matchesQuery('client', (_a = Parse.Query).or.apply(_a, queries));
            }
            console.log('userContext', userContext);
            if (userContext.isKavaUser) {
            }
            else if (userContext.lawFirmGroupObject) {
                // const lawFirmGroupQuery = new Parse.Query(Parse.Object.extend('ManagingAggregator'));
                // lawFirmGroupQuery.equalTo('objectId', userContext.lawFirmGroupId);
                query.matchesQuery('lawFirm', userContext.lawFirmGroupObject.relation('client_firm').query());
            }
            else if (userContext.lawFirmId) {
                var lawFirmQuery = new Parse.Query(Parse.Object.extend('LawFirm'));
                lawFirmQuery.equalTo('objectId', userContext.lawFirmId);
                query.matchesQuery('lawFirm', lawFirmQuery);
            }
            else if (userContext.hospitalId) {
                var hospitalQuery = new Parse.Query(Parse.Object.extend('Provider'));
                hospitalQuery.equalTo('objectId', userContext.hospitalId);
                query.matchesQuery('hospital', hospitalQuery);
            }
            else if (userContext.hospitalNetworkObject) {
                query.matchesQuery('hospital', userContext.hospitalNetworkObject.relation('members').query());
            }
            else {
                query.equalTo('objectId', null); // return nothing to unAuthroized users.
            }
            var twoDaysBefore = moment()
                .add(-2, 'days')
                .toDate();
            if (upcoming) {
                // query.greaterThan('createdAt', twoDaysBefore);
                // query.notEqualTo('status', AppointmentStatus.Cancelled);
                // query.notEqualTo('status', AppointmentStatus.CheckedIn);
                query.notContainedIn('status', [
                    AppointmentStatus.Cancelled,
                    AppointmentStatus.CheckedIn,
                    AppointmentStatus.Expired
                ]);
                query.ascending('createdAt');
            }
            else {
                // query.lessThan('createdAt', twoDaysBefore);
                query.containedIn('status', [
                    AppointmentStatus.Cancelled,
                    AppointmentStatus.CheckedIn,
                    AppointmentStatus.Expired
                ]);
                query.descending('createdAt');
            }
            return query;
        });
    };
    ParseProvider.prototype.getLawForms = function () {
        var _this = this;
        return this.getObjects('LawFirm', 0, 1000, function (query) {
            query.ascending('name');
            return query;
        }).then(function (res) {
            _this.lawFirms = res.results.map(function (x) { return LawFirm.createFromParseObject(x); });
        });
    };
    ParseProvider.prototype.getProviderNetworks = function () {
        var _this = this;
        return this.getObjects('ProviderNetwork', 0, 1000)
            .then(function (res) {
            _this.networkPFObjects = res.results;
            _this.networks = res.results.map(function (x) { return HospitalNetwork.createFromParseObject(x); });
        });
    };
    ParseProvider.prototype.resendReminder = function (appointment) {
        return Parse.Cloud.run('sendRemindSMS', { appointmentId: appointment.id });
    };
    ParseProvider.prototype.sendSMS = function (phone, message) {
        return Parse.Cloud.run('sendSMS', { toNumber: phone, message: message });
    };
    ParseProvider.prototype.postUser = function (userInfo) {
        return Parse.Cloud.run('modifyUser', userInfo);
        // return this.findUser(userInfo.id).then(user => {
        //   // userToSave = user;
        //   // userToSave.set('isKavaUser', userInfo.isKavaUser);
        //   // todo: must do via cloud function
        //   return Parse.Cloud.run('modifyUser', userInfo);
        // });
    };
    ParseProvider.prototype.postClient = function (clientInfo) {
        var _this = this;
        var clientToSave;
        if (clientInfo.id) {
            return this.findClient(clientInfo.id).then(function (client) {
                clientToSave = client;
                return _this.saveClientInfo(clientInfo, clientToSave);
            });
        }
        else {
            var ClientObject = Parse.Object.extend('Client');
            clientToSave = new ClientObject();
            return this.saveClientInfo(clientInfo, clientToSave);
        }
    };
    ParseProvider.prototype.saveClientInfo = function (clientInfo, clientToSave) {
        clientToSave.set('firstName', clientInfo.firstName);
        clientToSave.set('lastName', clientInfo.lastName);
        clientToSave.set('phone', clientInfo.phone);
        clientToSave.set('dateOfBirth', clientInfo.dateOfBirth);
        clientToSave.set('canBeContacted', clientInfo.canBeContacted === 'true');
        return clientToSave.save();
    };
    ParseProvider.prototype.createAppointmentForClient = function (client, hospitalId, appointmentId, lawFirwId) {
        var AppointmentObject = Parse.Object.extend('Appointment');
        var appointmentToSave = new AppointmentObject();
        if (appointmentId) {
            appointmentToSave.id = appointmentId;
        }
        appointmentToSave.set('createdBy', {
            __type: 'Pointer',
            className: '_User',
            objectId: this.authProvider.currentUser().id
        });
        appointmentToSave.set('hospital', {
            __type: 'Pointer',
            className: 'Provider',
            objectId: hospitalId
        });
        appointmentToSave.set('lawFirm', {
            __type: 'Pointer',
            className: 'LawFirm',
            objectId: lawFirwId || this.authProvider.currentUser().lawFirmId
        });
        appointmentToSave.set('client', {
            __type: 'Pointer',
            className: 'Client',
            objectId: client.id
        });
        return appointmentToSave;
    };
    ParseProvider.prototype.checkinAppointment = function (appointmentInfo) {
        return this.findAppointment(appointmentInfo.id).then(function (appointment) {
            appointment.set('status', AppointmentStatus.CheckedIn);
            appointment.set('checkedInAt', new Date());
            return appointment.save();
        });
    };
    ParseProvider.prototype.cancelAppointment = function (appointmentInfo) {
        return this.findAppointment(appointmentInfo.id).then(function (appointment) {
            appointment.set('status', AppointmentStatus.Cancelled);
            appointment.set('cancelledAt', new Date());
            return appointment.save();
        });
    };
    ParseProvider.prototype.postAppointment = function (appointmentInfo) {
        var _this = this;
        var ClientObject = Parse.Object.extend('Client');
        var clientToSave = new ClientObject();
        clientToSave.set('firstName', appointmentInfo.client.firstName);
        clientToSave.set('lastName', appointmentInfo.client.lastName);
        clientToSave.set('phone', appointmentInfo.client.phone);
        clientToSave.set('dateOfBirth', appointmentInfo.client.dateOfBirth);
        return clientToSave.save().then(function (client) {
            var appointmentToSave = _this.createAppointmentForClient(client, appointmentInfo.hospital.id, appointmentInfo.id, appointmentInfo.lawFirm ? appointmentInfo.lawFirm.id : null);
            appointmentInfo.familyMembers.forEach(function (familyMember) {
                _this.saveAppointmentForEachFamilyMember(familyMember, appointmentInfo.hospital.id, appointmentInfo.id, client.id, appointmentInfo.lawFirm ? appointmentInfo.lawFirm.id : null);
            });
            // all flow should touch the main client appointment save at last.
            // TODO: maybe in the future to merge all object to save into one list?
            return appointmentToSave.save();
        });
    };
    // for now, only hosipital can be changed in appointment
    ParseProvider.prototype.updateAppointment = function (appointmentInfo) {
        return this.findAppointment(appointmentInfo.id).then(function (res) {
            var appointmentToSave = res;
            appointmentToSave.set('status', 'rescheduled');
            appointmentToSave.set('hospital', {
                __type: 'Pointer',
                className: 'Provider',
                objectId: appointmentInfo.hospital.id
            });
            return appointmentToSave.save();
        });
    };
    ParseProvider.prototype.saveAppointmentForEachFamilyMember = function (familyMember, hospitalId, appointmentId, guardianId, lawFirmId) {
        var _this = this;
        var ClientObject = Parse.Object.extend('Client');
        var familyMemberToSave = new ClientObject();
        familyMemberToSave.set('firstName', familyMember.firstName);
        familyMemberToSave.set('lastName', familyMember.lastName);
        familyMemberToSave.set('phone', familyMember.phone);
        familyMemberToSave.set('dateOfBirth', familyMember.dateOfBirth);
        familyMemberToSave.set('canBeContacted', familyMember.canBeContacted === 'true');
        familyMemberToSave.set('guardian', {
            __type: 'Pointer',
            className: 'Client',
            objectId: guardianId
        });
        familyMemberToSave.save().then(function (addOn) {
            var appointmentForFamilyMember = _this.createAppointmentForClient(addOn, hospitalId, appointmentId, lawFirmId);
            return appointmentForFamilyMember.save();
        });
    };
    ParseProvider.prototype.postHospital = function (hospitalInfo) {
        var _this = this;
        var hospitalToSave;
        if (hospitalInfo.id) {
            return this.findHospital(hospitalInfo.id).then(function (hospital) {
                hospitalToSave = hospital;
                return _this.saveHospitalInfo(hospitalInfo, hospitalToSave);
            });
        }
        else {
            var HospitalObject = Parse.Object.extend('Provider');
            hospitalToSave = new HospitalObject();
            return this.saveHospitalInfo(hospitalInfo, hospitalToSave);
        }
    };
    ParseProvider.prototype.saveHospitalInfo = function (hospitalInfo, hospitalToSave) {
        console.log(hospitalInfo);
        hospitalToSave.set('name', hospitalInfo.name);
        hospitalToSave.set('street', hospitalInfo.street);
        hospitalToSave.set('city', hospitalInfo.city);
        hospitalToSave.set('state', hospitalInfo.state);
        hospitalToSave.set('zipCode', hospitalInfo.zipCode);
        hospitalToSave.set('phoneNumber', hospitalInfo.phoneNumber);
        var originalNetwork = hospitalToSave.get('network') || { id: null };
        if (hospitalInfo.networkId !== originalNetwork.id) {
            if (hospitalInfo.networkId) {
                hospitalToSave.set('network', {
                    __type: 'Pointer',
                    className: 'ProviderNetwork',
                    objectId: hospitalInfo.networkId
                });
                var newNetwork = this.networkPFObjects.find(function (n) { return n.id === hospitalInfo.networkId; });
                newNetwork.relation('members').add(hospitalToSave);
                newNetwork.save().then(function (res) { return console.log(res); }, function (e) { return console.error(e); });
            }
            else {
                hospitalToSave.set('network', null);
                if (originalNetwork.id) {
                    originalNetwork.relation('members').remove(hospitalToSave);
                    originalNetwork.save().then(function (res) { return console.log(res); }, function (e) { return console.error(e); });
                }
            }
        }
        // TODO: save relationship.
        return hospitalToSave.save();
    };
    ParseProvider.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ParseProvider_Factory() { return new ParseProvider(i0.ɵɵinject(i1.AuthProvider), i0.ɵɵinject(i2.Router)); }, token: ParseProvider, providedIn: "root" });
    return ParseProvider;
}());
export { ParseProvider };
