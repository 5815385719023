import { ParseProvider } from '../parse/parse';
import { BehaviorSubject } from 'rxjs';
import { Appointment, AppointmentListResponse, AppointmentsDisplayMode } from 'src/app/model/appointment';
import { AuthProvider } from '../auth/auth';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "../parse/parse";
import * as i2 from "../auth/auth";
import * as i3 from "@angular/router";
var AppointmentService = /** @class */ (function () {
    function AppointmentService(parseProvider, authProvider, router) {
        var _this = this;
        this.parseProvider = parseProvider;
        this.authProvider = authProvider;
        this.router = router;
        this.cachedUpcomingList = [];
        this.cachedRecentList = [];
        this.totalUpcomingCount = -1;
        this.totalRecentCount = -1;
        this.totalUpcomingSearchResultCount = 0;
        this.totalRecentSearchResultCount = 0;
        this.searchKeyword = '';
        this.upcomingListSubject = new BehaviorSubject(new AppointmentListResponse());
        this.recentListSubject = new BehaviorSubject(new AppointmentListResponse());
        this.displayMode = new BehaviorSubject(AppointmentsDisplayMode.Upcoming);
        this.authProvider.currentUser$().subscribe(function (user) {
            _this.currentUser = user;
            if (user == null) {
                _this.resetData(true);
            }
        });
    }
    Object.defineProperty(AppointmentService.prototype, "upcomingAppointments", {
        get: function () {
            return this.upcomingListSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppointmentService.prototype, "recentAppointments", {
        get: function () {
            return this.recentListSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppointmentService.prototype, "appontmentListDisplayMode", {
        get: function () {
            return this.displayMode.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    AppointmentService.prototype.setToUpcomingMode = function () {
        this.displayMode.next(AppointmentsDisplayMode.Upcoming);
    };
    AppointmentService.prototype.resetData = function (resetTotalCounts) {
        if (resetTotalCounts === void 0) { resetTotalCounts = false; }
        this.cachedUpcomingList = [];
        this.cachedRecentList = [];
        if (resetTotalCounts) {
            // when search keyword changes, no need to reset those values.
            this.totalUpcomingCount = -1;
            this.totalRecentCount = -1;
        }
        this.totalUpcomingSearchResultCount = 0;
        this.totalRecentSearchResultCount = 0;
        this.searchKeyword = '';
        this.upcomingListSubject.next(new AppointmentListResponse());
        this.recentListSubject.next(new AppointmentListResponse());
    };
    AppointmentService.prototype.loadData = function (searchKeyword, offset, isUpcomingMode, limit) {
        var _this = this;
        if (searchKeyword === void 0) { searchKeyword = null; }
        if (offset === void 0) { offset = 0; }
        if (isUpcomingMode === void 0) { isUpcomingMode = true; }
        if (limit === void 0) { limit = 20; }
        return this.parseProvider
            .getAppointments(this.currentUser, isUpcomingMode, searchKeyword, offset, limit)
            .then(function (response) {
            if (isUpcomingMode) {
                _this.setToUpcomingMode();
            }
            _this.searchKeyword = searchKeyword || '';
            if (isUpcomingMode) {
                if (_this.searchKeyword.length === 0) {
                    _this.totalUpcomingCount = response.totalCount;
                }
                else {
                    _this.totalUpcomingSearchResultCount = response.totalCount;
                }
                if (offset === 0) {
                    _this.cachedUpcomingList = [];
                }
                _this.cachedUpcomingList = _this.cachedUpcomingList.concat(response.results.map(function (x) { return Appointment.createFromParseObject(x); }));
                var res = new AppointmentListResponse(response.totalCount, _this.cachedUpcomingList);
                _this.upcomingListSubject.next(res);
                return res;
            }
            else {
                if (_this.searchKeyword.length === 0) {
                    _this.totalRecentCount = response.totalCount;
                }
                else {
                    _this.totalRecentSearchResultCount =
                        response.totalCountOfAppointmentsToDisplay;
                }
                if (offset === 0) {
                    _this.cachedRecentList = [];
                }
                _this.cachedRecentList = _this.cachedRecentList.concat(response.results.map(function (x) { return Appointment.createFromParseObject(x); }));
                var res = new AppointmentListResponse(response.totalCount, _this.cachedRecentList);
                _this.recentListSubject.next(res);
                return res;
            }
        }, function (err) {
            console.error('listappointments err', err);
            if (err.code === 209) {
                // 209 Invalid session token
                _this.authProvider.signout().subscribe(function (res) {
                    console.log('signout', res);
                });
                _this.router.navigate(['/signin']);
            }
            return new AppointmentListResponse(0, []);
        });
    };
    AppointmentService.prototype.checkIn = function (appointment) {
        var _this = this;
        return this.parseProvider.checkinAppointment(appointment).then(function (res) {
            var appointmentUpdated = Appointment.createFromParseObject(res);
            console.log('appointment checked in', appointmentUpdated);
            _this.cachedRecentList = [appointmentUpdated].concat(_this.cachedRecentList);
            _this.recentListSubject.next(new AppointmentListResponse(_this.cachedRecentList.length, _this.cachedRecentList));
            _this.cachedUpcomingList = _this.cachedUpcomingList.filter(function (x) { return x.id !== appointmentUpdated.id; });
            _this.upcomingListSubject.next(new AppointmentListResponse(_this.cachedUpcomingList.length, _this.cachedUpcomingList));
        });
    };
    AppointmentService.prototype.resendReminder = function (appointment) {
        return this.parseProvider.resendReminder(appointment);
    };
    AppointmentService.prototype.cancel = function (appointment) {
        var _this = this;
        return this.parseProvider.cancelAppointment(appointment).then(function (res) {
            var appointmentUpdated = Appointment.createFromParseObject(res);
            console.log('appointment cancelled', appointmentUpdated);
            _this.cachedRecentList = [appointmentUpdated].concat(_this.cachedRecentList);
            _this.recentListSubject.next(new AppointmentListResponse(_this.cachedRecentList.length, _this.cachedRecentList));
            _this.cachedUpcomingList = _this.cachedUpcomingList.filter(function (x) { return x.id !== appointmentUpdated.id; });
            _this.upcomingListSubject.next(new AppointmentListResponse(_this.cachedUpcomingList.length, _this.cachedUpcomingList));
        });
    };
    AppointmentService.prototype.update = function (appointment) {
        var _this = this;
        return this.parseProvider.updateAppointment(appointment).then(function (res) {
            var index = _this.cachedUpcomingList.indexOf(function (x) { return x.id === appointment.id; });
            if (index > -1) {
                _this.cachedUpcomingList = Object.assign([], _this.cachedUpcomingList, { indexRecent: appointment });
                _this.upcomingListSubject.next(new AppointmentListResponse(_this.cachedUpcomingList.length, _this.cachedUpcomingList));
            }
        });
    };
    AppointmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppointmentService_Factory() { return new AppointmentService(i0.ɵɵinject(i1.ParseProvider), i0.ɵɵinject(i2.AuthProvider), i0.ɵɵinject(i3.Router)); }, token: AppointmentService, providedIn: "root" });
    return AppointmentService;
}());
export { AppointmentService };
