import { Component, OnInit, OnDestroy } from '@angular/core';

import { Platform, ModalController, ToastController } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { version } from '../../package.json';
import { AuthProvider } from './services/auth/auth.js';
import { distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ParseProvider } from './services/parse/parse.js';
import { SplitPane } from './split-pane.js';
import { AppointmentConfirmationComponent } from './components/appointment-confirmation/appointment-confirmation.component.js';
import { AppointmentNewComponent } from './components/appointment-new/appointment-new.component.js';
import { AppointmentService } from './services/appointment/appointment.service.js';
import * as samever from 'semver';
import { environment } from 'src/environments/environment';
import { UpdateService } from './update-service.js';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  appVersionNumber: string = version;
  // public appPages = [];
  authSub: Subscription;
  isProd = environment.production;
  newVersionAvailable = false;

  appointmentPage =
    {
      title: 'Appointments',
      url: '/tabs/appointments',
      icon: 'bookmark'
    }
    ;

  profilePage =
    {
      title: 'Profile',
      url: '/tabs/profile',
      icon: 'person'
    }
    ;
  supportPage =
    {
      title: 'Support',
      url: '/tabs/support',
      icon: 'help'
    }
    ;
  termsPage =
    {
      title: 'Terms and Conditions',
      url: '/terms',
      icon: 'book'
    }
    ;

  newUsersPage = {
    title: 'New Users',
    url: '/tabs/users',
    icon: 'flash'
  };

  hospitalsPage = {
    title: 'Providers',
    url: '/tabs/hospitals',
    icon: 'business'
  };

  userListPage = {
    title: 'User List',
    url: '/tabs/user-list',
    icon: 'contacts'
  };

  constructor(
    public authService: AuthProvider,
    private parseProvider: ParseProvider,
    public splitPane: SplitPane,
    public modalController: ModalController,
    private appointmentProvider: AppointmentService,
    private platform: Platform,
    public toastController: ToastController,
    private update: UpdateService

  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();
      this.checkVersion();

      this.update.checkForUpdate();
    });
  }

  get username(): string {
    return this.authService.currentUser() ? `${this.authService.currentUser().firstName} ${this.authService.currentUser().lastName}` : null;
  }

  get isLawFirmUser(): boolean {
    return this.authService.currentUser() ? this.authService.currentUser().lawFirmId != null : false;
  }

  get userPages(): any[] {
    if (this.authService.currentUser()) {
      if (this.authService.currentUser().isKavaUser) {
        return [this.newUsersPage, this.appointmentPage, this.hospitalsPage, this.userListPage];
      }

      return [this.appointmentPage];
    }
    return [];

  }

  ngOnInit() {
    console.log('app start');

    this.authSub = this.authService.userState$
      .pipe(distinctUntilChanged())
      .subscribe(userState => {
        console.log('is user authenticated?', userState);
        // todo: this subscriber doesn't get update?
        // if (userState == null) {
        //   this.appPages = [];
        // } else {
        //   this.appPages = [this.appointmentPage, this.profilePage];
        //   if (userState.isKavaUser) {
        //     this.appPages = [this.newUsersPage, this.hospitalsPage, ...this.appPages];
        //   }
        // }
      });
  }

  onScheduleClient() {
    this.presentNewAppointmentModal();
  }


  async presentNewAppointmentModal() {
    this.appointmentProvider.setToUpcomingMode();

    const modal = await this.modalController.create({
      component: AppointmentNewComponent,
      componentProps: {}
    });

    modal.onDidDismiss().then(res => {
      if (res.data && res.data.success) {
        // this.listAppointments();

        // this.presentAppointmentConfirmationModal(res.data.result);
      }
    });

    return await modal.present();
  }

  checkVersion() {
    this.parseProvider.getConfig('latestVersion').then(ver => {
      console.log('latest ver:', ver, 'current ver:', this.appVersionNumber);
      if (samever.gt(ver, this.appVersionNumber)) {
        console.log('poping new version alert');
        this.newVersionAvailable = true;
        this.presentToast(`New version ${ver} is available, please refresh browser and wait a few seconds.`);
      }
    });
  }

  reloadPage() {
    window.location.reload();
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 5000,
      position: 'bottom',
      color: 'danger'
    });


    toast.present();
  }

  ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }

    // Plugins.App.removeListener('appStateChange', this.checkAuthOnResume);
  }

}
