<ion-content>
  <ion-row>
    <ion-col>

      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <img src="../../../assets/images/logo-white-no-text.png" style="width: 22px; height: 22px;"/>
          </ion-buttons>
          <ion-buttons slot="primary" *ngIf="isModalMode">
            <ion-button (click)="dismissModal()">
              <ion-icon slot="icon-only" name="close"></ion-icon>
            </ion-button>

          </ion-buttons>

          <ion-title>
            <strong>Support</strong>
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <div class="center">
        <img src="../../../assets/images/Support.svg" />
        <h1>
          <strong>Need our help? You got it! </strong>
        </h1>

        <h6>SCHEDULIING SUPPORT: 24/7
          <br/> GENERAL SUPPORT: 9:00AM - 7:00PM</h6>


        <ion-list>
          <ion-item (click)="callForSupport()">
            <img src="../../../assets/images/call.png">


            <ion-label class="support-label">+1.832.205.8938</ion-label>
          </ion-item>


          <ion-item (click)="emailForSupport()">
            <img src="../../../assets/images/email.png">


            <ion-label class="support-label">support@kavahealth.com</ion-label>
          </ion-item>
        </ion-list>
      </div>

    </ion-col>
  </ion-row>
</ion-content>

<ion-footer>
  <div class="ion-text-center">
    <small>Version {{appVersionNumber}}</small>
  </div>
</ion-footer>