<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>


    </ion-buttons>
    <ion-title>
      <strong>Terms & Conditions</strong>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <p class="p1">
    <strong>Terms of Use</strong>
  </p>
  <p class="p1">Last Revised: October 2019</p>
  <p class="p1">PLEASE READ THESE TERMS OF USE CAREFULLY. ACCESSING OR USING THIS WEBSITE, CONSTITUTES ACCEPTANCE OF THESE TERMS OF USE
    ("TERMS"), AS SUCH MAY BE REVISED BY KAVA HEALTH FROM TIME TO TIME, AND IS A BINDING AGREEMENT BETWEEN THE USER ("USER")
    AND KAVA HEALTH MANAGEMENT, LLC GOVERNING THE USE OF THE WEBSITE. IF USER DOES NOT AGREE TO THESE TERMS, USER SHOULD
    NOT ACCESS OR USE THIS WEBSITE. THESE TERMS CONTAIN DISCLAIMERS AND OTHER PROVISIONS THAT LIMIT OUR LIABILITY TO USER.</p>
  <p class="p1">These Terms apply to your access to, and use of, all or part of any website or mobile application of Kava Health Management,
    LLC or its subsidiaries and affiliated companies (collectively, "Kava Health"), including kavahealth.com, apps.kavaealth.com.
    Kavahealth.com and any other site, mobile application or online service where these Terms are posted (collectively, the
    "Sites"). These Terms do not alter in any way the terms or conditions of any other agreement you may have with Kava Health
    for products, services or otherwise.</p>
  <p class="p1">In the event there is any conflict or inconsistency between these Terms and any other terms of use that appear on the Sites,
    these Terms will govern. However, if you navigate away from the Sites to a third-party site, you may be subject to alternative
    terms and conditions of use, as may be specified on such site, which will govern your use of that site.</p>
  <p class="p1">While we make reasonable efforts to provide accurate and timely information about Kava Health on the Sites, you should
    not assume that the information is always up to date or that the Sites contain all the relevant information available
    about Kava Health.</p>
  <p class="p1">These terms include an Arbitration provision that governs any disputes between you and us. Unless you opt out, as described
    below, this provision will:</p>
  <p class="p1">Eliminate your right to a trial by jury; and</p>
  <p class="p1">Substantially affect your rights, including preventing you from bringing, joining or participating in class or consolidated
    proceedings.</p>
  <p class="p1">You agree that we may provide notices, disclosures and amendments to these Terms by electronic means, including by changing
    these Terms by posting revisions on the Sites.</p>
  <p class="p1">
    <em>Eligibility</em>
  </p>
  <p class="p1">
    <em>The Sites are not targeted towards, nor intended for use by, anyone under the age of 18. A USER MUST BE AT LEAST AGE
      18 TO ACCESS AND USE THE SITES. If the User is under the age of 18, he or she may only use the Sites under the supervision
      of a parent or legal guardian who agrees to be bound by these Terms and must also receive approval to use the Sites
      from Kava Health. User represents and warrants that (a) he/she is not located in a country that is subject to a U.S.
      government embargo, or that has been designated by the U.S. government as a "terrorist supporting" country; and (b)
      he/she is not listed on any U.S. government list of prohibited or restricted parties.</em>
  </p>
  <p class="p2">&nbsp;</p>
  <p class="p1">In order to participate in certain areas of our Sites, you will need to register for an account and be approved by Kava
    Health and/or its designees as an approved user granted permissioned access to the Sites. You agree to (a) create only
    one account; (b) provide accurate, truthful , current and complete information when creating your account; (c) maintain
    and promptly update your account information; (d) maintain the security of your account by not sharing your password
    with others and restricting access to your account and your computer; (e) promptly notify Kava Health if you discover
    or otherwise suspect any security breaches relating to the Sites; and (f) take responsibility for all activities that
    occur under your account and accept all risks of unauthorized access.</p>
  <p class="p1">Privacy</p>
  <p class="p1">Please read the Privacy Policy carefully to understand how Kava Health collects, uses and discloses personally identifiable
    information from its users. By accessing or using the Sites, you consent to all actions that we take with respect to
    your data consistent with our Privacy Policy.</p>
  <p class="p1">Email Communications</p>
  <p class="p1">If a User signs up for a Kava Health account on the Sites, the User is, by default, opted in to receive promotional email
    communications from Kava Health ("Email Communications"). The User may, at the time of sign up, opt out of receiving
    Email Communications from Kava Health. Thereafter, the User may opt out of receiving Email Communications by adjusting
    the User&rsquo;s profile settings in the User&rsquo;s Kava Health account via www.kavahealth.com.</p>
  <p class="p1">Copyright, Trademarks, and User License</p>
  <p class="p1">Unless otherwise indicated, the Sites and all content and other materials therein, including, without limitation, the Kava
    Health logo and all designs, text, graphics, pictures, information, data, software, sound files, other files and the
    selection and arrangement thereof (collectively, "Site Materials") are the property of Kava Health or its licensors or
    users and are protected by U.S. and international copyright laws. Kava Health, the Kava Health logo, and other Kava Health
    trademarks, service marks, graphics, and logos used in connection with the Sites are trade names, trademarks or registered
    trademarks of Kava Health Management (collectively "Kava Health Marks"). Other trademarks, service marks, graphics and
    logos used in connection with the Sites are the trademarks or registered trademarks of their respective owners (collectively
    "Third Party Marks"). The Kava Health Marks and Third-Party Marks may not be copied, imitated, or used, in whole or in
    part, without the prior written permission of Kava Health or the applicable trademark holder. The Sites and the Content
    are protected by copyright, trademark, patent, trade secret, international treaties, state and federal laws, and other
    proprietary rights and also may have security components that protect digital information only as authorized by Kava
    Health or the owner of the Content. All rights not expressly granted are reserved.</p>
  <p class="p1">Subject to these Terms, Kava Health grants the User a personal, non-exclusive, non-transferable, limited, and revocable
    license to use the Sites for personal use only in accordance with these Terms ("User License"). Any use of the Sites
    in any other manner, including, without limitation, resale, transfer, modification or distribution of the Sites or text,
    pictures, music, barcodes, video, data, hyperlinks, displays, and other content associated with the Sites ("Content")
    is prohibited. Unless explicitly stated herein, nothing in these Terms shall be construed as conferring in any manner,
    whether by implication, estoppel or otherwise, any title or ownership of, or exclusive use-rights to, any intellectual
    property or other right and any goodwill associated therewith These Terms and User License also govern any updates to,
    or supplements or replacements for, the Sites, unless separate terms accompany such updates, supplements, or replacements,
    in which case the separate terms will apply.</p>
  <p class="p1">Digital Millennium Copyright Act ("DMCA") Notice</p>
  <p class="p1">If you believe any material available via the Sites infringes a copyright you own or control, you may file a notification
    of such infringement with our Designated Agent as set forth below.</p>
  <p class="p3">Kava Health Management</p>
  <p class="p3">Customer Care: info@Kavahealth.com</p>
  <p class="p1">Please see 17 U.S.C. &sect;512(c)(3) for the requirements of a proper notification. You should note that if you knowingly
    misrepresent in your notification that the material or activity is infringing, you will be liable for any damages, including
    costs and attorneys' fees, incurred by us or the alleged infringer as the result of our relying upon such misrepresentation
    in removing or disabling access to the material or activity claimed to be infringing.</p>
  <p class="p1">If a notice of copyright infringement has been filed against material posted by you on the Sites, you may make a counter-notification
    with our Designated Agent listed above, provided that such counter-notification complies with the requirements of 17
    U.S.C. &sect;512(g)(3). If Kava Health receives a valid counter-notification, it may reinstate the removed or disabled
    material in accordance with the DMCA.</p>
  <p class="p1">In accordance with the DMCA and other applicable law, Kava Health has also adopted a policy of terminating, in appropriate
    circumstances and in our sole discretion, users who are deemed to be repeat infringers. Kava Health may also, in its
    sole discretion, limit access to the Sites and/or terminate the accounts of any users who infringe any intellectual property
    rights of others, whether or not there is any repeat infringement.</p>
  <p class="p1">Acceptable Use</p>
  <p class="p1">User&rsquo;s use of the Sites, any Content, and any information provided by the User including user names and passwords,
    addresses, e-mail addresses, phone number or employer name ("User Information") transmitted in connection with the Sites
    is limited to the contemplated functionality of the Sites. In no event may the Sites be used in a manner that (a) harasses,
    abuses, stalks, threatens, defames, or otherwise infringes or violates the rights of any other party (including but not
    limited to rights of publicity or other proprietary rights); (b) is unlawful, fraudulent, or deceptive; (c) provides
    sensitive personal information unless specifically requested by Kava Health, (d) includes spam or any unsolicited advertising;
    (e) uses technology or other means to access Kava Health or Content that is not authorized by Kava Health; (f) uses or
    launches any automated system, including without limitation, "robots," "spiders," or "offline readers," to access Kava
    Health or Content; (g) attempts to introduce viruses or any other computer code, files, or programs that interrupt, destroy,
    or limit the functionality of any computer software, hardware, or telecommunications equipment; (h) attempts to gain
    unauthorized access to Kava Health&rsquo; computer network or user accounts; (i) encourages conduct that would constitute
    a criminal offense or that gives rise to civil liability; (j) violates these Terms; (k) attempts to damage, disable,
    overburden, or impair Kava Health&rsquo; servers or networks; (l) impersonates any person or entity or otherwise misrepresents
    your identity or affiliation with another person or entity; or (m) fails to comply with applicable third party terms
    (collectively "Acceptable Use"). Kava Health reserves the right, in its sole discretion, to terminate any User License,
    terminate any User&rsquo;s participation in the Sites, remove Content, or assert legal action with respect to Content
    or use of the Sites, that Kava Health reasonably believes is or might be in violation of these Terms, or Kava Health
    policies including the Kava Health Card Terms and Conditions. Kava Health&rsquo; failure or delay in taking such actions
    does not constitute a waiver of its rights to enforce these Terms.</p>
  <p class="p1">User Content</p>
  <p class="p1">Kava Health does not control, take responsibility for or assume liability for any User Content posted, stored or uploaded
    by you or any third party, or for any loss or damage thereto, nor is Kava Health liable for any user conduct or any mistakes,
    defamation, slander, libel, omissions, falsehoods, obscenity, pornography or profanity you may encounter.</p>
  <p class="p1">If you become aware of User Content that you believe violates these Terms (with the exception of copyright infringement
    which is addressed in the Digital Millennium Copyright Act Notice section), you may report it by emailing legal@kavahealth.com.
    Enforcement of these Terms, however, is solely in our discretion and absence of enforcement in some instances does not
    constitute a waiver of our right to enforce the Terms in other instances. In addition, these Terms do not create any
    private right of action on the part of any third party or any reasonable expectation or promise that the Sites will not
    contain any content that is prohibited by these Terms. Although Kava Health has no obligation to screen, edit or monitor
    any of the User Content posted on the Sites, Kava Health reserves the right, and has absolute discretion, to remove,
    screen or edit any User Content on the Sites at any time and for any reason without notice. You are solely responsible
    for creating backup copies and replacing any User Content you post or store on the Sites at your sole cost and expense.</p>
  <p class="p1">If you are viewing the Sites on a public computer or are otherwise using a computer to which multiple people have potential
    access, be sure to follow all relevant instructions to ensure you are sufficiently disconnected and logged off the Sites
    and the computer system you are using to prevent unauthorized User Content.</p>
  <p class="p1">Submission of Ideas</p>
  <p class="p1">Separate and apart from the User Content you provide, you may submit questions, comments, feedback, suggestions, ideas,
    improvements, plans, notes, drawings, original or creative materials or other information about Kava Health, our Sites
    and our products (collectively, "Ideas"). The Ideas you submit are voluntary, non-confidential, gratuitous and non-committal.
    Please do not send us Ideas if you expect to be paid or want to continue to own or claim rights in them; your Ideas might
    be great, but we may have already had the same or similar idea and we do not want disputes. You must also inform us if
    you have a pending or registered patent relative to the Idea.</p>
  <p class="p2">&nbsp;</p>
  <p class="p1">You represent and warrant that your Idea is not subject to any confidentiality obligations or third party intellectual
    property encumbrances and that you own and control all of the rights to the Idea and have the authority to grant the
    rights to Kava Health that you grant herein.</p>
  <p class="p1">By submitting your Idea, you grant Kava Health and its designees a worldwide, perpetual, irrevocable, non-exclusive, fully-paid
    up and royalty free license to use, sell, reproduce, prepare derivative works, combine with other works, alter, translate,
    distribute copies, display, perform, publish, license or sub-license the Idea and shall be entitled to the unrestricted
    use and dissemination of Ideas for any purpose, commercial or otherwise, without acknowledgment or compensation to you.
    By submitting your Idea, you hereby release Kava Health and its agents and employees from any claims that such use violates
    any of your rights.</p>
  <p class="p1">Kava Health shall own exclusive rights, including all intellectual property rights, to any work it creates or has created
    from the Idea or a similar idea of its own.</p>
  <p class="p1">Indemnification</p>
  <p class="p1">The User agrees to defend, indemnify, and hold harmless Kava Health, its parent, subsidiary and other affiliated companies,
    independent contractors, service providers and consultants, and their respective employees, contractors, agents, officers,
    and directors ("Kava Health Indemnitees") from any and all claims, suits, damages, costs, lawsuits, fines, penalties,
    liabilities, and expenses (including attorneys&rsquo; fees) ("Claims") that arise from or relate to the User&rsquo;s
    use or misuse of the Sites, violation of these Terms, violation of any rights of a third party, any User Content or Ideas
    you provide, or your conduct in connection with the Sites. Notwithstanding the foregoing, this indemnification provision
    shall not apply to any Claims caused by a Kava Health Indemnitee&rsquo;s sole negligence. Kava Health reserves the right
    to assume the exclusive defense and control of any matter otherwise subject to indemnification by the User, in which
    event the User will cooperate in asserting any available defenses.</p>
  <p class="p1">Warranties; Disclaimers</p>
  <p class="p1">KAVA HEALTH IS PROVIDING THE SITES TO THE USER "AS IS" AND THE USER IS USING THE SITES AT HIS OR HER OWN RISK. TO THE FULLEST
    EXTENT ALLOWABLE UNDER APPLICABLE LAW, KAVA HEALTH DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING ANY
    WARRANTIES THAT THE SITES ARE MERCHANTABLE, RELIABLE, AVAILABLE, ACCURATE, FIT FOR A PARTICULAR PURPOSE OR NEED, NON-INFRINGING,
    FREE OF DEFECTS OR VIRUSES, ABLE TO OPERATE ON AN UNINTERRUPTED BASIS, THAT THE USE OF THE SITES BY THE USER IS IN COMPLIANCE
    WITH LAWS APPLICABLE TO THE USER, OR THAT USER INFORMATION TRANSMITTED IN CONNECTION WITH THE SITES WILL BE SUCCESSFULLY,
    ACCURATELY, OR SECURELY TRANSMITTED OR RECEIVED. THE MATERIALS AND INFORMATION ON THE SITES MAY INCLUDE TECHNICAL INACCURACIES
    OR TYPOGRAPHICAL ERRORS. NOTWITHSTANDING THE FOREGOING, NONE OF THE DISCLAIMERS IN THIS PARAGRAPH SHALL APPLY TO WARRANTIES
    RELATED TO PERSONAL INJURY.</p>
  <p class="p1">No Liability</p>
  <p class="p1">SUBJECT TO APPLICABLE LAW, INCLUDING WITH RESPECT TO LIABILITY FOR PERSONAL INJURY OR NON-WAIVABLE STATUTORY RIGHTS UNDER
    TEXAS LAW, IN NO EVENT SHALL KAVA HEALTH OR ITS OFFICERS, DIRECTORS, EMPLOYEES, SHAREHOLDERS OR AGENTS (A) BE LIABLE
    TO THE USER WITH RESPECT TO USE OF THE SITES, THE CONTENT OR THE MATERIALS CONTAINED IN OR ACCESSED THROUGH THE SITES
    (INCLUDING WITHOUT LIMITATION ANY DAMAGES CAUSED BY OR RESULTING FROM RELIANCE BY A USER ON ANY INFORMATION OBTAINED
    FROM KAVA HEALTH), OR ANY DAMAGES THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR EMAIL, ERRORS,
    DEFECTS, VIRUSES, DELAYS IN OPERATION OR TRANSMISSION OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS
    OF GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO KAVA HEALTH' RECORDS, PROGRAMS OR SERVICES;
    AND (B) BE LIABLE TO THE USER FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES, INCLUDING,
    WITHOUT LIMITATION, DAMAGES FOR LOSS OF GOODWILL, LOST PROFITS, LOSS, THEFT OR CORRUPTION OF USER INFORMATION, OR THE
    INABILITY TO USE THE SITES OR ANY OF THEIR FEATURES. THE USER&rsquo;S SOLE REMEDY IS TO CEASE USE OF THE SITES.</p>
  <p class="p1">IF YOU RESIDE IN A JURISDICTION OTHER THAN TEXAS, YOUR JURISDICTION MAY NOT ALLOW THE LIMITATION OF LIABILITY IN CONTRACTS
    WITH CONSUMERS, SO SOME OR ALL OF THESE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.</p>
  <p class="p1">Third Party Content, Sites, Products and Services (including Advertising and Promotions)</p>
  <p class="p1">Kava Health may provide third party content on the Sites (including embedded content) or links to third-party web pages,
    content, applications, products and services, including advertisements and promotions (collectively, "Third Party Content")
    as a service to those interested in this information. We do not control, endorse or adopt any Third-Party Content, including
    that the inclusion of any link does not imply affiliation, endorsement or adoption by Kava Health of any site or any
    information contained therein, and can make no guarantee as to its accuracy or completeness. You acknowledge and agree
    that Kava Health is not responsible or liable in any manner for any Third-Party Content and undertakes no responsibility
    to update or review such Third-Party Content. You agree to use such Third-Party Content contained therein at your own
    risk. When you visit other sites via Third Party Content, or participate in promotions or business dealings with third
    parties, you should understand that our terms and policies no longer govern, and that the terms and policies of those
    third-party sites will now apply. You should review the applicable terms and policies, including privacy and data gathering
    practices, of any site to which you navigate from our Sites. You must comply with any applicable third-party terms when
    using the Sites.</p>
  <p class="p1">Modifications to the Sites</p>
  <p class="p1">Kava Health reserves the right to modify or discontinue, temporarily or permanently, the Sites or any features or portions
    thereof without prior notice. You agree that Kava Health will not be liable for any modification, suspension or discontinuance
    of the Sites or any part thereof.</p>
  <p class="p1">Financial Material Disclosures</p>
  <p class="p1">Forward-Looking Statements: The Sites, and any documents issued by Kava Health and available through the Sites, may contain
    statements which constitute forward-looking statements within the meaning of the U.S. Private Securities Litigation Reform
    Act of 1995. Forward-looking statements can be identified by the fact that they do not relate strictly to historical
    or current facts. They often include words such as "believes," "expects," "anticipates," "estimates," "intends," "plans,"
    "seeks" or words of similar meaning, or future or conditional verbs, such as "will," "should," "could" or "may." Forward-looking
    statements include statements made as to future operations, costs, capital expenditures, cash flow, product developments,
    operating efficiencies, sales and earnings estimates or trends and expansion plans, initiatives and projections. These
    forward-looking statements are based on our expectations as of the date such forward-looking statements are made and
    are neither predictions nor guarantees of future events or circumstances. Actual future results and trends may differ
    materially depending on a variety of factors. The company assumes no obligation to update any of these forward-looking
    statements.</p>
  <p class="p1">Press Releases: The information contained within press releases issued by Kava Health should not be deemed accurate or
    current except as of the date the release was posted. Kava Health specifically disclaims any duty to update, the information
    in the press releases. To the extent any information therein is forward-looking it is intended to fit within the safe
    harbor for forward-looking statements and is subject to material risk.</p>
  <p class="p1">Arbitration</p>
  <p class="p1">Please read this section carefully. It affects rights that you may otherwise have. It provides for resolution of most disputes
    through arbitration instead of court trials and class actions. Arbitration is more informal than a lawsuit in court,
    uses a neutral arbitrator instead of a judge or jury, and discovery is more limited. Arbitration is final and binding
    and subject to only very limited review by a court. This arbitration clause shall survive termination of these Terms.</p>
  <p class="p1">Binding Arbitration. This provision is intended to be interpreted broadly to encompass all disputes or claims arising out
    of or relating to these Terms, your use of the Sites, and your relationship with us. Any dispute or claim arising out
    of or relating to these Terms or use of the Sites and your relationship with Kava Health or any subsidiary, parent or
    affiliate company or companies (whether based in contract, tort, statute, fraud, misrepresentation or any other legal
    theory) will be resolved by binding arbitration, except that either of us may take claims to small claims court if they
    qualify for hearing by such a court.</p>
  <p class="p1">
    <em>Opt-Out. Notwithstanding the above, you may choose to pursue your claim in court and not by arbitration if you opt out
      of this arbitration provision within 30 days from the earliest of the date you downloaded, installed, accessed or used
      the Sites (the "Opt Out Deadline") after these Terms have gone into effect. You may opt out of these arbitration procedures
      by sending us a written notice that you opt out to the following address: Kava Health Management, XXXXX, Delaware,
      XXXXX. Any opt-out received after the Opt Out Deadline (allowing three (3) additional days for mailing) will not be
      valid and you must pursue your claim in arbitration or small claims court.</em>
  </p>
  <p class="p1">Arbitration Procedures. For all disputes, whether pursued in court or arbitration, you must first send a written description
    of your claim to our Customer Service department to allow us an opportunity to resolve the dispute. You and we each agree
    to negotiate your claim in good faith. You may request arbitration if your claim or dispute cannot be resolved within
    60 days.</p>
  <p class="p1">The arbitration of any dispute or claim shall be conducted in accordance with the rules of the American Arbitration Association
    ("AAA"), including the AAA's Consumer Arbitration Rules (as applicable), as modified by these Terms. The AAA Rules and
    information about arbitration and fees are available online at www.adr.org. You and we agree that these Terms evidence
    a transaction in interstate commerce and this arbitration provision will be interpreted and enforced in accordance with
    the U.S. Federal Arbitration Act and federal arbitration law, and not governed by state law. Any arbitration will be
    held in a reasonably convenient location in the state in which you reside or at another mutually agreed location. The
    arbitration will be conducted in the English language. An arbitrator may award on an individual basis any relief that
    would be available in a court, including injunctive or declaratory relief to the extent required to satisfy your individual
    claim, and must follow and enforce these Terms as a court would. Any arbitration shall be confidential, and neither you
    nor we may disclose the existence, content or results of any arbitration, except as may be required by law or for purposes
    of enforcement of the arbitration award. Judgment on any arbitration award may be entered in any court having proper
    jurisdiction.</p>
  <p class="p1">
    <em>Costs of Arbitration. Each party will bear their share of any arbitration fees, fees and expense of its own attorneys,
      experts, witnesses and preparation and presentation of evidence at the arbitration. </em>
  </p>
  <p class="p1">Class Action Waiver and Jury Waiver. You and we each agree that any proceeding, whether in arbitration or in court, will
    be conducted only on an individual basis and not in a class, consolidated or representative action. If a court or arbitrator
    determines in an action between you and us that this class action waiver is unenforceable, the arbitration agreement
    will be void as to you. If you opt out of the arbitration provision as specified above, this class action waiver provision
    will not apply to you. Neither you, nor any other customer, can be a class representative, class member, or otherwise
    participate in a class, consolidated or representative proceeding without having complied with the opt out procedure
    set forth above. If for any reason a claim proceeds in court rather than through arbitration, you and we each waive any
    right to a jury trial.</p>
  <p class="p1">Governing Law and Jurisdiction</p>
  <p class="p1">These Terms and use of the Sites are governed by the laws of the state of Texas, United States of America, without regard
    to Texas&rsquo; conflict of laws rules. If the arbitration agreement is ever deemed unenforceable or void, the User irrevocably
    consents to the exclusive jurisdiction of the federal and state courts in Harris County, Texas, United States of America,
    for purposes of any legal action arising out of or related to the use of the Sites or these Terms.</p>
  <p class="p1">Termination</p>
  <p class="p1">Notwithstanding any of these Terms, Kava Health reserves the right, without notice and in its sole discretion, to terminate
    your license to use the Sites and to block or prevent your future access to and use of the Sites. Kava Health&rsquo;
    failure or delay in taking such actions does not constitute a waiver of its rights to enforce these Terms.</p>
  <p class="p1">Changes</p>
  <p class="p1">Kava Health reserves the right to change or modify these Terms or any other Kava Health policies related to use of the
    Sites at any time and at its sole discretion by posting revisions on the Sites. Continued use of the Sites following
    such changes or modifications to the Terms or other Kava Health policies will constitute acceptance of such changes or
    modifications.</p>
  <p class="p1">Severability</p>
  <p class="p1">If any provision of these Terms shall be deemed unlawful, void or for any reason unenforceable, then that provision shall
    be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions.</p>
</ion-content>