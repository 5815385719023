<ion-content>
  <ion-row class="ion-align-items-center">
    <ion-col class="align-self-center">
      <ion-header translucent>
        <ion-toolbar>
            <ion-buttons slot="start" *ngIf="!isModalMode">
                <ion-menu-button></ion-menu-button>
          
              </ion-buttons>
          <ion-title>
            <strong>{{ familyMemberMode ? 'Add to Reservation' : 'New Reservation' }}</strong>
          </ion-title>
          <ion-buttons slot="end" *ngIf="isModalMode">
            <ion-button icon-only (click)="dismissModal(false)" large>
              <ion-icon name="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>


      <form #f="ngForm" [hidden]="isReview">
        <ion-list>
          <ng-container *ngIf="currentUser.isKavaUser || currentUser.lawFirmGroupId">
  
          <ion-item>
            <ion-label position="start">Law Firm</ion-label>
            <ion-select placeholder="Select One" required [(ngModel)]="lawFirmSelected" name="lawFirm"  [compareWith]="compareWith">
              <ion-select-option [value]="lawFirm" *ngFor="let lawFirm of lawFirms">{{lawFirm.name}}</ion-select-option>
             </ion-select>
          </ion-item>
          </ng-container>

          <ion-item-divider>CLIENT DETAILS</ion-item-divider>
          <ion-item>
            <ion-label position="start">First Name</ion-label>
            <ion-input position="end" autocapitalize class="ion-text-center" [(ngModel)]="clientToSave.firstName" name="firstName" type="text"
              required></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="start">Last Name</ion-label>
            <ion-input position="end" autocapitalize class="ion-text-center" [(ngModel)]="clientToSave.lastName" name="lastName" type="text"
              required></ion-input>
          </ion-item>
          <ion-item>

            <ion-label>Date Of Birth</ion-label>
            <ion-input position="end" class="ion-text-end" [(ngModel)]="clientToSave.dateOfBirth" name="dateOfBirth" type="date" required>
            </ion-input>
            <!-- <ion-datetime require slot="end" name="dateOfBirth" display-format="MM/DD/YYYY" picker-format="MMM DD, YYYY" [max]="maxBirthDate"
              [(ngModel)]="clientToSave.dateOfBirth" required></ion-datetime> -->
          </ion-item>

          <ion-item>
            <ion-label position="start" class="ion-text-wrap">Client or Guardian Mobile Phone</ion-label>
            <ion-input position="end" class="ion-text-center" type="phone " placeholder="(555)555-5555" inputmode="tel " [(ngModel)]="clientToSave.phone"
              name="phone" pattern="\(\d{3}\)\d{3}-\d{4}" #phoneNumber="ngModel" (ionChange)="onClientPhoneNumberChanged($event)"
              required></ion-input>
          </ion-item>
          <ion-item *ngIf=" phoneNumber.invalid && (f.submitted || phoneNumber.touched)" lines="none">
            <ion-label color="danger" class="ion-text-wrap">
              <small *ngIf="phoneNumber?.errors.required"> Phone number is required. </small>
              <small *ngIf="phoneNumber?.errors.pattern"> Phone number is invalid.</small>
              <small *ngIf="phoneNumber?.errors.fake"> Phone number is not valid.</small>
            </ion-label>
          </ion-item>

          <ion-item-divider>SELECT FACILITY</ion-item-divider>
          <ion-item (click)="presentHospitalPickerModal()" detail>
            <ion-label>Select Facility</ion-label>
            <ion-label color="primary" slot="end" class="ion-text-end">{{hospitalPicked?.name || 'Pick One'}}</ion-label>


          </ion-item>
          <ion-item>
            <ion-chip *ngFor="let recent of recentHospitals" ion-button (click)="onPickRecentHospital(recent)">


              <ion-label>
                {{recent.name}}
              </ion-label>
            </ion-chip>

          </ion-item>
        </ion-list>
      </form>

      <ng-container *ngIf="isReview && !familyMemberMode">
        <ion-list>
          <ion-item-divider>CONFIRM RESERVATION DETAILS</ion-item-divider>
          <ion-card>
            <ion-card-content>
              <ion-button class="ion-float-right" fill="clear" size="small" (click)="onRevise()">
                <ion-icon slot="icon-only" name="create"></ion-icon>
              </ion-button>
              <dl>
                <dt>Name:</dt>
                <dd>{{ this.clientToSave.firstName }} {{ this.clientToSave.lastName }}</dd>
                <dt>DOB:</dt>
                <dd>{{ this.clientToSave.dateOfBirth }}</dd>
                <dt>Phone:</dt>
                <dd>{{ this.clientToSave.phone }}</dd>
                <dt>Hospital:</dt>
                <dd>{{ this.appointmentToSave.hospital.name }}</dd>
                <dt>Address:</dt>
                <dd>{{ this.appointmentToSave.hospital.address }}</dd>
              </dl>



            </ion-card-content>

          </ion-card>

          <ng-container *ngIf="appointmentToSave.familyMembers.length > 0">
            <ion-item-divider>Family Member Add-On</ion-item-divider>
            <ion-card *ngFor="let familyMember of appointmentToSave.familyMembers">
              <ion-card-content>
                <ion-button class="ion-float-right" fill="clear" size="small" (click)="onDeleteFamilyMember(familyMember)">
                  <ion-icon slot="icon-only" name="trash"></ion-icon>
                </ion-button>
                <ion-button class="ion-float-right" fill="clear" size="small" (click)="goEditingFamilyMember(familyMember)">
                  <ion-icon slot="icon-only" name="create"></ion-icon>
                </ion-button>
                <dl>
                  <dt>Name:</dt>
                  <dd>{{ familyMember.firstName}} {{familyMember.lastName}}</dd>
                  <dt>DOB:</dt>
                  <dd>{{ this.familyMember.dateOfBirth }}</dd>
                  <dt>Phone:</dt>
                  <dd>{{ this.familyMember.phone }}</dd>
                  <dt>Communication Preference:</dt>
                  <dd>{{ this.familyMember.canBeContacted === 'true' ? familyMember.fullName : clientToSave.fullName }} receives
                    communication updates </dd>
                </dl>

              </ion-card-content>

            </ion-card>
          </ng-container>

          <ion-row>
            <ion-col>
              <ion-button fill="outline" expand="block" (click)="onRevise()">REVISE</ion-button>
            </ion-col>
            <ion-col>
              <ion-button fill="outline" expand="block" (click)="goAddingFamilyMember()">ADD FAMILY MEMBER</ion-button>
            </ion-col>
          </ion-row>



          <ion-item>
            <ion-label class="ion-text-center ion-text-wrap">
              <small>
                If you would like to add a family member to join the first patient in their visit. click "Add Family Member" otherwise, you
                may click "Confirm and Complete" to finalize the appointment.</small>
            </ion-label>
          </ion-item>
        </ion-list>
      </ng-container>



      <form #f2="ngForm" *ngIf="familyMemberMode">
        <ion-list>
          <ion-item-divider>FAMILY MEMBER ADD-ON</ion-item-divider>
          <ion-item>
            <ion-label position="start">First Name</ion-label>
            <ion-input position="end" autocapitalize class="ion-text-center" [(ngModel)]="familyMemberToSave.firstName" name="familyMemberFirstName"
              type="text" required>
            </ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="start">Last Name</ion-label>
            <ion-input position="end" autocapitalize class="ion-text-center" [(ngModel)]="familyMemberToSave.lastName" name="familyMemberLastName"
              type="text" required>
            </ion-input>
          </ion-item>
          <ion-item>

            <ion-label>Date Of Birth</ion-label>
            <ion-input position="end" class="ion-text-end" [(ngModel)]="familyMemberToSave.dateOfBirth" name="dateOfBirth" type="date"
              required>
            </ion-input>
            <!-- <ion-datetime require slot="end" name="dateOfBirth" display-format="MM/DD/YYYY" picker-format="MMM DD, YYYY" [max]="maxBirthDate"
              [(ngModel)]="familyMemberToSave.dateOfBirth" required></ion-datetime> -->
          </ion-item>
          <ion-item>
            <ion-label position="start" class="ion-text-wrap">Client or Guardian Mobile Phone</ion-label>

            <ion-input type="phone " position="end" class="ion-text-center" inputmode="tel " [(ngModel)]="familyMemberToSave.phone" name="phone"
              placeholder="(555)555-5555" pattern="\(\d{3}\)\d{3}-\d{4}" #phoneNumber="ngModel" (ionChange)="onAddOnPhoneNumberChanged($event)"></ion-input>
          </ion-item>
          <ion-item *ngIf=" phoneNumber.invalid && (f.submitted || phoneNumber.touched)" lines="none">
            <ion-label color="danger" class="ion-text-wrap">
              <small *ngIf="phoneNumber?.errors.required"> Phone number is required. </small>
              <small *ngIf="phoneNumber?.errors.pattern"> Phone number is invalid.</small>
              <small *ngIf="phoneNumber?.errors.fake"> Phone number is invalid.</small>
            </ion-label>
          </ion-item>

          <ion-radio-group [(ngModel)]="familyMemberToSave.canBeContacted" name="familyMemberCanBeContacted" required *ngIf="familyMemberToSave.phone">

            <ion-item lines="none">
              <ion-label>
                Client receives communication updates
              </ion-label>
              <ion-radio value="true"></ion-radio>
            </ion-item>

            <ion-item>
              <ion-label>
                {{guardianName}} receives communication updates
              </ion-label>
              <ion-radio value="false"></ion-radio>
            </ion-item>
          </ion-radio-group>

        </ion-list>

        <ion-row>
          <ion-col>
            <ion-button fill="outline" expand="block" (click)="onCancelAddOn()">CANCEL ADD-ON</ion-button>
          </ion-col>
          <ion-col>
            <ion-button fill="outline" expand="block" (click)="confirmToAddFamilyMember()" [disabled]="!f2.valid ">CONFIRM ADD-ON
            </ion-button>
          </ion-col>
        </ion-row>


      </form>
      <ion-footer *ngIf="!isReview">
        <ion-button fill="solid " expand="block " color="primary " (click)="goNext() " [disabled]="!f.valid || hospitalPicked == null">
          <strong>NEXT
          </strong>
        </ion-button>

      </ion-footer>
      <ion-footer *ngIf="isReview && !familyMemberMode">
        <ion-button fill="solid " expand="block " color="primary " (click)="complete() " [disabled]="!f.valid ">
          <strong>CONFIRM AND COMPLETE</strong>
        </ion-button>

      </ion-footer>
    </ion-col>
  </ion-row>
</ion-content>