<ion-header translucent>
  <ion-toolbar>
    <ion-title>
      <strong>Hospital Picker</strong>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button icon-only (click)="dismissModal(false)" large>
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-searchbar placeholder="Type zip code to find nearby hospitals" debounce="500" animated (ionChange)="onSearch($event)"
    inputmode="numeric">
  </ion-searchbar>
  <ion-item *ngIf="errorMsg">
    <ion-label color="danger" class="ion-text-wrap">
      <small> {{errorMsg}} </small>
    </ion-label>
  </ion-item>
  <ion-list>
    <ion-item *ngFor="let hospital of hospitals" (click)="onPickHospital(hospital)">
      <small slot="end" *ngIf="hospital.distance" class="ion-text-end">{{hospital.distance | number : '1.2-2'}} miles</small>

      <ion-label class="ion-text-wrap">
        <h2>{{hospital.name }}</h2>
        <p>{{hospital.address}}</p>
      </ion-label>

      <ion-icon color="primary" name="checkmark-circle" slot="end" *ngIf="hospitalPicked && hospital.id === hospitalPicked.id"></ion-icon>
    </ion-item>
  </ion-list>
</ion-content>