import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppointmentNewComponent } from './components/appointment-new/appointment-new.component';
import { SharedModule } from './components/shared.module';
import { AppointmentConfirmationComponent } from './components/appointment-confirmation/appointment-confirmation.component';
import { CheckinConfirmationComponent } from './components/checkin-confirmation/checkin-confirmation.component';
import { SupportPage } from './pages/support/support.page';
import { SupportPageModule } from './pages/support/support.module';
import { TermsPage } from './pages/terms/terms.page';
import { TermsPageModule } from './pages/terms/terms.module';
import { HospitalPickerComponent } from './components/hospital-picker/hospital-picker.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import * as Sentry from 'sentry-cordova';
import { SentryIonicErrorHandler } from './error-handler';
import { version } from '../../package.json';
import { SplitPane } from './split-pane';
import { AppointmentNewComponentModule } from './components/appointment-new/appointment-new.module';
import { PwaInstallPopOverComponent } from './components/pwa-install-pop-over/pwa-install-pop-over.component';

Sentry.init({
  dsn: 'https://7310a42ad5b14f508f1e20c30f57cb50@sentry.io/2113320',
  release: environment.production ? `kava-health@${version}` : `kava-health-uat@${version}`
});
@NgModule({
  declarations: [AppComponent],
  entryComponents: [
    AppointmentNewComponent,
    AppointmentConfirmationComponent,
    CheckinConfirmationComponent,
    SupportPage,
    TermsPage,
    HospitalPickerComponent,
    PwaInstallPopOverComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    SupportPageModule,
    TermsPageModule,
    HttpClientModule,
    AppointmentNewComponentModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    HttpClient,
    SplitPane,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: SentryIonicErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
