import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

// Parse
import { Parse } from 'parse';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

export class User {
  public id?: string;
  public name: string;
  public firstName?: string;
  public middleName?: string;

  public lastName?: string;
  public email: string;
  public phone: string;

  public password?: string;
  public lawFirmId?: string;
  public lawFirmGroupId?: string;
  public lawFirmGroupObject?: any;
  public lawFirmName?: string;
  public hospitalId?: string;
  public hospitalName?: string;
  public hospitalNetworkId?: string;
  public hospitalNetworkName?: string;
  public hospitalNetworkObject?: any;
  public isKavaUser: boolean;
  public notificationPref: string;
  public createdAt: string;

  public static createFromParseObject(userObj: any): User {
    const user = new User();
    user.id = userObj.id;
    user.name = userObj.get('username');
    user.email = userObj.get('email');
    user.phone = userObj.get('phone');

    user.firstName = userObj.get('firstName');
    user.middleName = userObj.get('middleName');

    user.lastName = userObj.get('lastName');
    user.lawFirmId = userObj.get('lawFirm') ? userObj.get('lawFirm').id : null;
    user.lawFirmName = userObj.get('lawFirm') ? userObj.get('lawFirm').get('name') : null;
    user.hospitalId = userObj.get('hospital')
      ? userObj.get('hospital').id
      : null;
    user.hospitalName = userObj.get('hospital')
      ? userObj.get('hospital').get('name')
      : null;
    user.hospitalNetworkId = userObj.get('providerNetwork')
      ? userObj.get('providerNetwork').id
      : null;
    user.hospitalNetworkName = userObj.get('providerNetwork')
      ? userObj.get('providerNetwork').get('name')
      : null;
    // user.hospitalNetwork = userObj.get('providerNetwork');

    user.isKavaUser = userObj.get('isKavaUser');
    user.lawFirmGroupId = userObj.get('lawFirmGroup') ? userObj.get('lawFirmGroup').id : null;
    
    user.notificationPref = userObj.get('notificationPref') || 'email';
    user.createdAt = moment(userObj.createdAt).format('LLL');

    return user;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthProvider {
  private parseAppId: string = environment.parseAppId;
  private parseJsKey: string = environment.parseJsKey;
  private parseServerUrl: string = environment.parseServerUrl;

  public currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(
    null
  );
  constructor() {
    this.parseInitialize();

    const isAuthenticated = this.currentUser() != null;
    if (isAuthenticated) {
      this.currentUserSubject.next(this.currentUser());
    
      if (this.currentUser().lawFirmGroupId) {
        const query = new Parse.Query(
          Parse.Object.extend('ManagingAggregator')
        );
        query.equalTo('objectId', this.currentUser().lawFirmGroupId);
        query.first().then(res => {
          const u = this.currentUser();
          u.lawFirmGroupObject = res;
          console.log('lawFirmGroupObject found', res);
          this.currentUserSubject.next(u);              
        });
      }  
    }
  }

  getSessionTokenByPhoneNumber(phone: string) {
    return Parse.Cloud.run('getSessionTokenByPhoneNumber', { phone });
  }

  becomeUser(sessionToken: any) {
    console.log('becomeUser', sessionToken);
    return Parse.User.become(sessionToken).then(user => {
      this.currentUserSubject.next(User.createFromParseObject(user));

      if (user.lawFirmGroupId) {
        const query = new Parse.Query(
          Parse.Object.extend('ManagingAggregator')
        );
        query.equalTo('objectId', this.currentUser().lawFirmGroupId);
        query.first().then(res => {
          const u = this.currentUser();
          u.lawFirmGroupObject = res;
          console.log('lawFirmGroupObject found', res);
          this.currentUserSubject.next(u);              
        });
      }
    });
  }

  public signin(username: string, password: string): Observable<boolean> {
    const self = this;
    return new Observable(observer => {
      Parse.User.logIn(username, password)
        .then(user => {
          console.log(
            'User signed in successful with name: ' +
            user.get('username') +
            ' and email: ' +
            user.get('email')
          );

          // if user has is not kava user, no lawfirm id, no hospital/network. force quit
          if (
            !user.get('isKavaUser') &&
            user.get('lawFirm') == null &&
            user.get('hospital') == null &&
            user.get('providerNetwork') == null
          ) {
            console.error('no role assigned yet');

            observer.error('No role assigned to you yet.');
            self.currentUserSubject.next(null);
            observer.complete();
            return;
          }

          observer.next(true);
          const usr = User.createFromParseObject(user);
          console.log('user logged in', usr);
          self.currentUserSubject.next(usr);
          if (this.currentUser().hospitalNetworkId) {
            const query = new Parse.Query(
              Parse.Object.extend('ProviderNetwork')
            );
            query.equalTo('objectId', this.currentUser().hospitalNetworkId);
            query.first().then(res => {
              const u = this.currentUser();
              u.hospitalNetworkObject = res;
              self.currentUserSubject.next(u);
            });
          }

          if (this.currentUser().lawFirmGroupId) {
            const query = new Parse.Query(
              Parse.Object.extend('ManagingAggregator')
            );
            query.equalTo('objectId', this.currentUser().lawFirmGroupId);
            query.first().then(res => {
              const u = this.currentUser();
              u.lawFirmGroupObject = res;
              console.log('lawFirmGroupObject found', res);
              self.currentUserSubject.next(u);              
            });
          }

          observer.complete();
        })
        .catch(error => {
          console.error('Error: ' + error.code + ' ' + error.message);
          observer.error(error);
          self.currentUserSubject.next(null);
          observer.complete();
          return;
        });
    });
  }
 

  public signup(
    firstName: string,
    middleName: string,
    lastName: string,
    password: string,
    email: string,
    phone: string
  ): Observable<boolean> {
    const self = this;
    return new Observable(observer => {
      const user = new Parse.User();
      user.set('firstName', firstName.trim().toLocaleLowerCase());
      user.set('middleName', middleName.trim().toLocaleLowerCase());
      user.set('lastName', lastName.trim().toLocaleLowerCase());
      user.set('username', email.trim().toLocaleLowerCase());
      user.set('password', password);
      user.set('email', email.trim().toLocaleLowerCase());
      user.set('phone', phone.trim().toLocaleLowerCase());

      user
        .signUp()
        .then(u => {
          console.log(
            'User created successful with name: ' +
            u.get('username') +
            ' and email: ' +
            u.get('email')
          );
          observer.next(true);
          self.currentUserSubject.next(User.createFromParseObject(u));
          observer.complete();
        })
        .catch(error => {
          console.log('Error: ' + error.code + ' ' + error.message);
          observer.error(error);
          observer.complete();
        });
    });
  }

  public signout(): Observable<boolean> {
    const self = this;
    return new Observable(observer => {
      Parse.User.logOut().then(
        () => {
          observer.next(true);
          self.currentUserSubject.next(null);
        },
        error => {
          console.log('lougout error:', error);
          observer.error(error); // todo: maybe only allow error no 209 (invalid session token)

          self.currentUserSubject.next(null);

        }
      );
    });
  }

  public get userState$(): Observable<User> {
    return this.currentUserSubject.asObservable();
  }

  public currentUser$(): Observable<User> {
    return this.currentUserSubject.asObservable();
  }

  public currentUser(): User {
    const u = Parse.User.current();
    if (u) {
      return User.createFromParseObject(u);
    }
    return null;
  }

  public authenticated(): boolean {
    const isAuthenticated = this.currentUser() != null;
    // if (isAuthenticated) {
    //   this.currentUserSubject.next(this.currentUser());
    // }

    return isAuthenticated;
  }

  // public isAdmin(): boolean {
  //   return this.authenticated() && this.currentUser().isAdmin === true;
  // }

  private parseInitialize() {
    Parse.initialize(this.parseAppId, this.parseJsKey);
    Parse.serverURL = this.parseServerUrl;
  }

  requestPasswordReset(email: string): Promise<any> {
    return Parse.User.requestPasswordReset(email);
  }

  changePassword(newPassword: string): Promise<any> {
    const user = Parse.User.current();
    user.setPassword(newPassword);
    return user.save();
  }

  changeUserProfile(userInfo: User): Promise<User> {
    const user = Parse.User.current();
    user.set('firstName', userInfo.firstName);
    user.set('middleName', userInfo.middleName);
    user.set('lastName', userInfo.lastName);
    user.set('email', userInfo.email.toLocaleLowerCase());
    user.set('username', userInfo.email.toLocaleLowerCase());
    user.set('phone', userInfo.phone);
    user.set('notificationPref', userInfo.notificationPref);
    return user
      .save()
      .then(u => this.currentUserSubject.next(User.createFromParseObject(u)));
  }
}
