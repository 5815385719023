import { Component, OnInit, NgZone } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ParseProvider } from '../../services/parse/parse';
import { HttpClient } from '@angular/common/http';
import { HospitalService } from '../../services/hospital/hospital.service';
import { Hospital } from 'src/app/model/hospital';

@Component({
  selector: 'app-hospital-picker',
  templateUrl: './hospital-picker.component.html',
  styleUrls: ['./hospital-picker.component.scss']
})
export class HospitalPickerComponent implements OnInit {
  hospitals = [];
  hospitalPicked: Hospital;
  errorMsg: string;

  constructor(
    public modalController: ModalController,
    private parseProvider: ParseProvider,
    private navParams: NavParams,
    private http: HttpClient,
    private zone: NgZone,
    private hospitalProvider: HospitalService
  ) {
    this.hospitalPicked = this.navParams.data.hospitalPicked as Hospital;

    this.loadHospitals();
  }

  ngOnInit() { }

  dismissModal(success) {
    this.modalController.dismiss({ success });
  }

  loadHospitals() {
    this.hospitalProvider.getHospitals().then(res => {
      this.hospitals = [...res];
    });
  }

  onPickHospital(hospital) {
    this.modalController.dismiss({ success: true, hospital });
  }

  onSearch(event) {
    this.errorMsg = null;
    const zipCode = event.target.value;
    if (/^[0-9]{5}(?:-[0-9]{4})?$/.test(zipCode)) {
      console.log('valid zip code', zipCode);

      this.hospitalProvider.sortHospitalsByDistanceTo(zipCode).then(
        res => {
          console.log('sorted', res);
          const cloneList = [...this.hospitals];
          this.hospitals = cloneList.map(x => {
            const y = new Hospital();
            y.address = x.address;
            y.id = x.id;
            y.name = x.name;
            y.zipCode = x.zipCode;
            y.distance = x.zipCode === zipCode ? 0 : res[x.zipCode];
            return y;
          });
          this.hospitals = this.hospitals.sort(
            (a, b) => a.distance - b.distance
          );
          this.zone.run(() => { });
        },
        err => {
          this.errorMsg = err.message.data.error_msg;
          this.zone.run(() => { });
          console.error(err.message.data.error_msg);
        }
      );
    } else {
      if (this.hospitals.find(x => x.distance)) {
        this.hospitals = this.hospitals.map(x => {
          x.distance = null;
          return x;
        });
      }
    }
  }
}
