import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import * as Sentry from 'sentry-cordova';
var SentryIonicErrorHandler = /** @class */ (function (_super) {
    tslib_1.__extends(SentryIonicErrorHandler, _super);
    function SentryIonicErrorHandler() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SentryIonicErrorHandler.prototype.handleError = function (error) {
        _super.prototype.handleError.call(this, error);
        try {
            Sentry.captureException(error.originalError || error);
        }
        catch (e) {
            console.error(e);
        }
    };
    return SentryIonicErrorHandler;
}(ErrorHandler));
export { SentryIonicErrorHandler };
