import * as moment from 'moment';
import { Hospital } from './hospital';
var AppointmentListResponse = /** @class */ (function () {
    function AppointmentListResponse(totalCount, entries) {
        if (totalCount === void 0) { totalCount = -1; }
        if (entries === void 0) { entries = []; }
        this.totalCount = totalCount;
        this.entries = entries;
    }
    return AppointmentListResponse;
}());
export { AppointmentListResponse };
export var AppointmentsDisplayMode;
(function (AppointmentsDisplayMode) {
    AppointmentsDisplayMode[AppointmentsDisplayMode["Upcoming"] = 0] = "Upcoming";
    AppointmentsDisplayMode[AppointmentsDisplayMode["Recent"] = 1] = "Recent";
})(AppointmentsDisplayMode || (AppointmentsDisplayMode = {}));
var Client = /** @class */ (function () {
    function Client() {
    }
    Object.defineProperty(Client.prototype, "fullName", {
        get: function () {
            return this.firstName + " " + this.lastName;
        },
        enumerable: true,
        configurable: true
    });
    Client.createFromParseObject = function (object) {
        if (object == null) {
            return null;
        }
        var result = new Client();
        result.id = object.id;
        result.firstName = object.get('firstName');
        result.lastName = object.get('lastName');
        result.phone = object.get('phone');
        if (result.phone) {
            result.phoneRipped = object.get('phone')
                .replace('(', '')
                .replace(')', '')
                .replace('-', '')
                .replace('', '');
        }
        result.dateOfBirth = object.get('dateOfBirth');
        result.canBeContacted = object.get('canBeContacted') ? 'true' : 'false';
        result.guardian = object.get('guardian') ? Client.createFromParseObject(object.get('guardian')) : null;
        return result;
    };
    return Client;
}());
export { Client };
var LawFirm = /** @class */ (function () {
    function LawFirm() {
    }
    LawFirm.createFromParseObject = function (object) {
        var result = new LawFirm();
        result.id = object.id;
        result.name = object.get('name');
        result.address = object.get('street') + " " + object.get('city') + ", " + object.get('state') + " " + object.get('zip');
        return result;
    };
    return LawFirm;
}());
export { LawFirm };
export var AppointmentStatus;
(function (AppointmentStatus) {
    AppointmentStatus["CheckedIn"] = "checked-in";
    AppointmentStatus["Cancelled"] = "cancelled";
    AppointmentStatus["Expired"] = "expired";
})(AppointmentStatus || (AppointmentStatus = {}));
var Appointment = /** @class */ (function () {
    function Appointment() {
    }
    Object.defineProperty(Appointment.prototype, "checkedInTime", {
        get: function () {
            return moment(this.checkedInAt).format('LLL');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Appointment.prototype, "cancellTime", {
        get: function () {
            return moment(this.cancelledAt).format('LLL');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Appointment.prototype, "scheduledTimeString", {
        get: function () {
            return moment(this.createdAt).format('MMM Do, YYYY');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Appointment.prototype, "hoursTillAutoCancel", {
        get: function () {
            var hoursPassed = moment().diff(this.createdAt, 'hours');
            return 48 - hoursPassed;
        },
        enumerable: true,
        configurable: true
    });
    Appointment.createFromParseObject = function (object) {
        var result = new Appointment();
        result.id = object.id;
        result.createdAt = object.createdAt;
        // result.updatedAt = object.updatedAt;
        result.checkedInAt = object.get('checkedInAt');
        result.status = object.get('status');
        result.client = Client.createFromParseObject(object.get('client'));
        result.hospital = Hospital.createFromParseObject(object.get('hospital'));
        result.lawFirm = Hospital.createFromParseObject(object.get('lawFirm'));
        return result;
    };
    return Appointment;
}());
export { Appointment };
