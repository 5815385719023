import { Injectable } from '@angular/core';
import * as libphonenumber from 'google-libphonenumber';

@Injectable({
  providedIn: 'root'
})
export class PhoneService {
  constructor() {}

  isValidNumber(phoneNumber: string): boolean {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    try {
      const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, 'US');
      if (phoneUtil.isPossibleNumber(parsedNumber)) {
        return phoneUtil.isValidNumber(parsedNumber);
      }

      return false;
    } catch (err) {
      // console.error(err);
      return false;
    }
  }

  format(phoneNumber: string): string {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    return phoneUtil.formatInOriginalFormat(phoneNumber, 'US');
  }

  noExcepetion(func) {
    let ret;
    try {
      ret = func();
    } catch (err) {
      ret = 'ERROR';
    } finally {
    }
  }
}
