import * as tslib_1 from "tslib";
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
import * as i2 from "@ionic/angular";
var UpdateService = /** @class */ (function () {
    function UpdateService(swUpdate, toastController) {
        this.swUpdate = swUpdate;
        this.toastController = toastController;
    }
    UpdateService.prototype.checkForUpdate = function () {
        var _this = this;
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(function () {
                console.log('an app update is available');
                if (confirm('New version is available. Load New Version Now?')) {
                    _this.updateToLatest();
                }
            });
            // this.swUpdate
            //   .checkForUpdate()
            //   .then(() => {
            //     console.log('Checking for updates...');
            //   })
            //   .catch(err => {
            //     console.error('Error when checking for update', err);
            //   });
        }
    };
    UpdateService.prototype.updateToLatest = function () {
        var _this = this;
        console.log('Updating to latest version.');
        this.swUpdate.activateUpdate().then(function () {
            _this.presentToast("New version has been installed! Restarting app now...");
            console.log('New version sucessfully updated, but you might not see this log.');
        });
    };
    UpdateService.prototype.presentToast = function (message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastController.create({
                            message: message,
                            duration: 5000,
                            position: 'bottom',
                            color: 'danger'
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        toast.onDidDismiss().then(function () {
                            // console.log('Dismissed toast');
                            window.location.reload();
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    UpdateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateService_Factory() { return new UpdateService(i0.ɵɵinject(i1.SwUpdate), i0.ɵɵinject(i2.ToastController)); }, token: UpdateService, providedIn: "root" });
    return UpdateService;
}());
export { UpdateService };
