import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { AppointmentNewComponent } from './appointment-new/appointment-new.component';
import { AppointmentConfirmationComponent } from './appointment-confirmation/appointment-confirmation.component';
import { CheckinConfirmationComponent } from './checkin-confirmation/checkin-confirmation.component';
import { HospitalPickerComponent } from './hospital-picker/hospital-picker.component';
import { AppointmentNewComponentModule } from './appointment-new/appointment-new.module';
import { PwaInstallPopOverComponent } from './pwa-install-pop-over/pwa-install-pop-over.component';



@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule
  ],
  declarations: [
    AppointmentConfirmationComponent,
    CheckinConfirmationComponent,
    HospitalPickerComponent,
    PwaInstallPopOverComponent

  ],
  exports: [
    AppointmentConfirmationComponent,
    CheckinConfirmationComponent,
    HospitalPickerComponent,
    PwaInstallPopOverComponent

  ]
})
export class SharedModule { }
