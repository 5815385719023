import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Appointment } from '../../model/appointment';

@Component({
  selector: 'app-appointment-confirmation',
  templateUrl: './appointment-confirmation.component.html',
  styleUrls: ['./appointment-confirmation.component.scss']
})
export class AppointmentConfirmationComponent implements OnInit {
  appointment: Appointment;

  constructor(
    public modalController: ModalController,
    private navParams: NavParams
  ) {
    this.appointment = this.navParams.data.appointment as Appointment;
  }

  ngOnInit() {}

  dismissModal(keepMakingNewAppointment) {
    this.modalController.dismiss({ keepMakingNewAppointment });
  }
}
