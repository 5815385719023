import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  constructor(
    private swUpdate: SwUpdate,
    public toastController: ToastController
  ) {}

  checkForUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        console.log('an app update is available');
        if (confirm('New version is available. Load New Version Now?')) {
          this.updateToLatest();
        }
      });

      // this.swUpdate
      //   .checkForUpdate()
      //   .then(() => {
      //     console.log('Checking for updates...');
      //   })
      //   .catch(err => {
      //     console.error('Error when checking for update', err);
      //   });
    }
  }

  updateToLatest(): void {
    console.log('Updating to latest version.');
    this.swUpdate.activateUpdate().then(() => {
      this.presentToast(`New version has been installed! Restarting app now...`);
      console.log(
        'New version sucessfully updated, but you might not see this log.'
      );
    });
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 5000,
      position: 'bottom',
      color: 'danger'
    });

    toast.present();

    toast.onDidDismiss().then(() => {
      // console.log('Dismissed toast');
      window.location.reload();
    });
  }
}
