import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AuthProvider } from './services/auth/auth';

@Injectable({
    providedIn: 'root'
  })
export class SplitPane {
  splitPaneState: boolean;
  constructor(public platform: Platform, private authProvider: AuthProvider) {
    this.splitPaneState = false;
  }

  getSplitPane() {
    if (this.authProvider.currentUser()) {
      if (this.platform.width() > 728) {
        this.splitPaneState = true;
      } else {
        this.splitPaneState = false;
      }
    } else {
      this.splitPaneState = false;
    }
    return this.splitPaneState;
  }
}
