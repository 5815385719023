/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pwa-install-pop-over.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./pwa-install-pop-over.component";
var styles_PwaInstallPopOverComponent = [i0.styles];
var RenderType_PwaInstallPopOverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PwaInstallPopOverComponent, data: {} });
export { RenderType_PwaInstallPopOverComponent as RenderType_PwaInstallPopOverComponent };
export function View_PwaInstallPopOverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "ion-row", [], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(1, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "ion-col", [["class", "ion-align-self-center"], ["size", "2"]], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(3, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { size: [0, "size"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 0, "img", [["src", "../../../assets/icon/icons8-add-new-100.png"], ["style", " width: 30px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, 0, 5, "ion-col", [], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(6, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Install this webapp on your iOS device: tap "])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["src", "../../../assets/icon/icons8-upload-50.png"], ["style", "height: 22px; width: 22px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" and then Add to Home Screen. "]))], function (_ck, _v) { var currVal_0 = "2"; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_PwaInstallPopOverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pwa-install-pop-over", [], null, null, null, View_PwaInstallPopOverComponent_0, RenderType_PwaInstallPopOverComponent)), i1.ɵdid(1, 114688, null, 0, i4.PwaInstallPopOverComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PwaInstallPopOverComponentNgFactory = i1.ɵccf("app-pwa-install-pop-over", i4.PwaInstallPopOverComponent, View_PwaInstallPopOverComponent_Host_0, {}, {}, []);
export { PwaInstallPopOverComponentNgFactory as PwaInstallPopOverComponentNgFactory };
