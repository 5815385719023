/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hospital-picker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "./hospital-picker.component";
import * as i7 from "../../services/parse/parse";
import * as i8 from "@angular/common/http";
import * as i9 from "../../services/hospital/hospital.service";
var styles_HospitalPickerComponent = [i0.styles];
var RenderType_HospitalPickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HospitalPickerComponent, data: {} });
export { RenderType_HospitalPickerComponent as RenderType_HospitalPickerComponent };
function View_HospitalPickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-item", [], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "ion-label", [["class", "ion-text-wrap"], ["color", "danger"]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(3, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "danger"; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.errorMsg; _ck(_v, 5, 0, currVal_1); }); }
function View_HospitalPickerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "small", [["class", "ion-text-end"], ["slot", "end"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " miles"])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.distance, "1.2-2")); _ck(_v, 1, 0, currVal_0); }); }
function View_HospitalPickerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-icon", [["color", "primary"], ["name", "checkmark-circle"], ["slot", "end"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(1, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], name: [1, "name"] }, null)], function (_ck, _v) { var currVal_0 = "primary"; var currVal_1 = "checkmark-circle"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_HospitalPickerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "ion-item", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPickHospital(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_HospitalPickerComponent_3)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 5, "ion-label", [["class", "ion-text-wrap"]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(5, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_HospitalPickerComponent_4)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.distance; _ck(_v, 3, 0, currVal_0); var currVal_3 = (_co.hospitalPicked && (_v.context.$implicit.id === _co.hospitalPicked.id)); _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_1); var currVal_2 = _v.context.$implicit.address; _ck(_v, 9, 0, currVal_2); }); }
export function View_HospitalPickerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 13, "ion-header", [["translucent", ""]], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(2, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { translucent: [0, "translucent"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 11, "ion-toolbar", [], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(4, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "ion-title", [], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(6, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hospital Picker"])), (_l()(), i1.ɵeld(9, 0, null, 0, 5, "ion-buttons", [["slot", "end"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(10, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 3, "ion-button", [["icon-only", ""], ["large", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissModal(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(12, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(13, 0, null, 0, 1, "ion-icon", [["name", "close"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(14, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 11, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(16, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(17, 0, null, 0, 3, "ion-searchbar", [["animated", ""], ["debounce", "500"], ["inputmode", "numeric"], ["placeholder", "Type zip code to find nearby hospitals"]], null, [[null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 20)._handleInputEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ionChange" === en)) {
        var pd_2 = (_co.onSearch($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonSearchbar_0, i2.RenderType_IonSearchbar)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.TextValueAccessor]), i1.ɵdid(19, 49152, null, 0, i3.IonSearchbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { animated: [0, "animated"], debounce: [1, "debounce"], inputmode: [2, "inputmode"], placeholder: [3, "placeholder"] }, null), i1.ɵdid(20, 16384, null, 0, i3.TextValueAccessor, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_HospitalPickerComponent_1)), i1.ɵdid(22, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(23, 0, null, 0, 3, "ion-list", [], null, null, null, i2.View_IonList_0, i2.RenderType_IonList)), i1.ɵdid(24, 49152, null, 0, i3.IonList, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_HospitalPickerComponent_2)), i1.ɵdid(26, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); var currVal_1 = "close"; _ck(_v, 14, 0, currVal_1); var currVal_2 = ""; var currVal_3 = "500"; var currVal_4 = "numeric"; var currVal_5 = "Type zip code to find nearby hospitals"; _ck(_v, 19, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.errorMsg; _ck(_v, 22, 0, currVal_6); var currVal_7 = _co.hospitals; _ck(_v, 26, 0, currVal_7); }, null); }
export function View_HospitalPickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hospital-picker", [], null, null, null, View_HospitalPickerComponent_0, RenderType_HospitalPickerComponent)), i1.ɵdid(1, 114688, null, 0, i6.HospitalPickerComponent, [i3.ModalController, i7.ParseProvider, i3.NavParams, i8.HttpClient, i1.NgZone, i9.HospitalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HospitalPickerComponentNgFactory = i1.ɵccf("app-hospital-picker", i6.HospitalPickerComponent, View_HospitalPickerComponent_Host_0, {}, {}, []);
export { HospitalPickerComponentNgFactory as HospitalPickerComponentNgFactory };
