import * as tslib_1 from "tslib";
import { OnInit, NgZone } from '@angular/core';
import { ModalController, LoadingController, ToastController } from '@ionic/angular';
import { Client, Appointment, LawFirm } from '../../model/appointment';
import * as moment from 'moment';
import { ParseProvider } from '../../services/parse/parse';
import { AuthProvider } from '../../services/auth/auth';
import { AppointmentConfirmationComponent } from '../appointment-confirmation/appointment-confirmation.component';
import { PhoneService } from 'src/app/services/phone/phone.service';
import { NgForm } from '@angular/forms';
import { HospitalPickerComponent } from '../hospital-picker/hospital-picker.component';
import { HospitalService } from '../../services/hospital/hospital.service';
import { Router } from '@angular/router';
import { Hospital } from 'src/app/model/hospital';
var AppointmentNewComponent = /** @class */ (function () {
    function AppointmentNewComponent(modalController, parseProvider, authProvider, loadCtrl, phoneService, toastController, hospitalProvider, router, zone) {
        var _this = this;
        this.modalController = modalController;
        this.parseProvider = parseProvider;
        this.authProvider = authProvider;
        this.loadCtrl = loadCtrl;
        this.phoneService = phoneService;
        this.toastController = toastController;
        this.hospitalProvider = hospitalProvider;
        this.router = router;
        this.zone = zone;
        this.familyMemberMode = false;
        this.isReview = false;
        this.lawFirms = [];
        this.hospitals = [];
        this.recentHospitals = [];
        this.compareWith = function (o1, o2) {
            return o1 && o2 ? o1.id === o2.id : o1 === o2;
        };
        this.authProvider.currentUser$().subscribe(function (user) {
            _this.currentUser = user;
            if (_this.currentUser) {
                if (_this.currentUser.isKavaUser) {
                    _this.loadLawFirms();
                }
                else if (_this.currentUser.lawFirmGroupId) {
                    _this.loadLawFirmGroupClients();
                }
            }
        });
    }
    AppointmentNewComponent.prototype.ngOnInit = function () {
        this.intitializeData();
    };
    AppointmentNewComponent.prototype.ionViewWillEnter = function () {
        this.loadRecentHospitals();
        console.log('dob', this.clientToSave.dateOfBirth, this.clientForm.value.dateOfBirth);
    };
    AppointmentNewComponent.prototype.loadRecentHospitals = function () {
        var _this = this;
        this.parseProvider
            .getRecentHospitals(this.authProvider.currentUser())
            .then(function (res) { return (_this.recentHospitals = res.map(function (x) { return Hospital.createFromParseObject(x); })); });
    };
    AppointmentNewComponent.prototype.loadHospitals = function () {
        var _this = this;
        this.hospitalProvider.getHospitals().then(function (res) {
            _this.hospitals = res;
        });
    };
    AppointmentNewComponent.prototype.loadLawFirms = function () {
        this.lawFirms = this.parseProvider.lawFirms;
        // this.parseProvider.getLawForms().then(
        //   res => {
        //     this.lawFirms = res.results.map(x => LawFirm.createFromParseObject(x));
        //   });
    };
    AppointmentNewComponent.prototype.loadLawFirmGroupClients = function () {
        var _this = this;
        this.lawFirms = [];
        this.parseProvider.findLawFirmGroup(this.currentUser.lawFirmGroupId).then(function (res) {
            _this.parseProvider.findLawFirmGroupMembers(res).each(function (client) {
                return _this.lawFirms.push(LawFirm.createFromParseObject(client));
            });
            console.log('lawfirms models', _this.lawFirms);
        });
    };
    AppointmentNewComponent.prototype.dismissModal = function (success) {
        this.modalController.dismiss({ success: success });
    };
    AppointmentNewComponent.prototype.onPickRecentHospital = function (hospital) {
        this.appointmentToSave.hospital = hospital;
        this.hospitalPicked = hospital;
    };
    Object.defineProperty(AppointmentNewComponent.prototype, "maxBirthDate", {
        get: function () {
            return moment()
                .add(0, 'years')
                .format('YYYY-MM-DD');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppointmentNewComponent.prototype, "guardianName", {
        get: function () {
            return this.clientToSave.firstName + " " + this.clientToSave.lastName;
        },
        enumerable: true,
        configurable: true
    });
    AppointmentNewComponent.prototype.onHospitalIdSelected = function () {
        var _this = this;
        this.appointmentToSave.hospital = this.hospitals.find(function (x) { return x.id === _this.hospitalPicked.id; });
    };
    AppointmentNewComponent.prototype.goNext = function () {
        this.clientToSave.dateOfBirth = moment(this.clientToSave.dateOfBirth).format('YYYY-MM-DD');
        if (this.phoneService.isValidNumber(this.clientToSave.phone)) {
            this.isReview = true;
        }
        else {
            this.presentToast('Client phone number is invalid', false);
        }
    };
    AppointmentNewComponent.prototype.onRevise = function () {
        this.isReview = false;
    };
    AppointmentNewComponent.prototype.goAddingFamilyMember = function () {
        this.familyMemberMode = true;
        this.familyMemberToSave = new Client();
        this.familyMemberToSave.dateOfBirth = moment().format('YYYY-MM-DD');
        this.familyMemberToSave.id = null; // identify this is new record.
    };
    AppointmentNewComponent.prototype.goEditingFamilyMember = function (familyMember) {
        this.familyMemberMode = true;
        this.familyMemberToSave = familyMember;
    };
    AppointmentNewComponent.prototype.onDeleteFamilyMember = function (familyMember) {
        var index = this.appointmentToSave.familyMembers.indexOf(familyMember);
        if (index !== -1) {
            this.appointmentToSave.familyMembers.splice(index, 1);
        }
    };
    AppointmentNewComponent.prototype.onCancelAddOn = function () {
        this.familyMemberMode = false;
        this.familyMemberToSave = null;
    };
    AppointmentNewComponent.prototype.confirmToAddFamilyMember = function () {
        // TODO: save new family member
        this.familyMemberToSave.dateOfBirth = moment(this.familyMemberToSave.dateOfBirth).format('YYYY-MM-DD');
        if (this.familyMemberToSave.id == null) {
            this.familyMemberToSave.id = ''; // wipe out flag
            this.appointmentToSave.familyMembers.push(this.familyMemberToSave);
        }
        if (this.phoneService.isValidNumber(this.clientToSave.phone)) {
            this.familyMemberMode = false;
        }
        else {
            this.presentToast('Phone number is invalid', false);
        }
    };
    AppointmentNewComponent.prototype.complete = function () {
        var _this = this;
        // save
        if (this.lawFirmSelected) {
            this.appointmentToSave.lawFirm = this.lawFirmSelected;
        }
        var loader = this.loadCtrl
            .create({
            message: 'Saving...'
        })
            .then(function (loadingEl) {
            loadingEl.present();
            _this.parseProvider.postAppointment(_this.appointmentToSave).then(function (res) {
                _this.parseProvider.findAppointment(res.id).then(function (object) {
                    loadingEl.dismiss();
                    var result = Appointment.createFromParseObject(object);
                    if (_this.isModalMode) {
                        _this.modalController.dismiss({
                            success: true,
                            result: result
                        });
                    }
                    else {
                        _this.router.navigateByUrl('/tabs/appointments');
                        _this.presentAppointmentConfirmationModal(result);
                        _this.intitializeData();
                    }
                    //   // let modal = this.modalController.create(AppointmentConfirmationComponent, { data: data }, { cssClass: 'select-modal' });
                    //   this.presentAppointmentConfirmationModal();
                });
            });
        });
    };
    AppointmentNewComponent.prototype.presentToast = function (message, success) {
        if (success === void 0) { success = true; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastController.create({
                            message: message,
                            duration: 2000,
                            color: success ? 'success' : 'danger'
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppointmentNewComponent.prototype.onClientPhoneNumberChanged = function (event) {
        if (this.clientToSave.phone && this.clientToSave.phone.length === 10) {
            this.clientToSave.phone = this.formatPhoneNumber(this.clientToSave.phone);
        }
        if (!this.phoneService.isValidNumber(this.clientToSave.phone)) {
            // tslint:disable-next-line: no-string-literal
            this.clientForm.form.controls['phone'].setErrors({ fake: true });
        }
    };
    AppointmentNewComponent.prototype.onAddOnPhoneNumberChanged = function (event) {
        if (this.familyMemberToSave.phone.length === 10) {
            this.familyMemberToSave.phone = this.formatPhoneNumber(this.familyMemberToSave.phone);
        }
        if (!this.phoneService.isValidNumber(this.familyMemberToSave.phone)) {
            // tslint:disable-next-line: no-string-literal
            this.addOnForm.form.controls['phone'].setErrors({ fake: true });
        }
    };
    AppointmentNewComponent.prototype.formatPhoneNumber = function (phoneNumberString) {
        return phoneNumberString.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
    };
    AppointmentNewComponent.prototype.presentHospitalPickerModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: HospitalPickerComponent,
                            componentProps: { hospitalPicked: this.hospitalPicked }
                        })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (res) {
                            if (res.data && res.data.success) {
                                _this.hospitalPicked = res.data.hospital;
                                _this.appointmentToSave.hospital = res.data.hospital;
                            }
                        });
                        return [4 /*yield*/, modal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AppointmentNewComponent.prototype.presentAppointmentConfirmationModal = function (appointment) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: AppointmentConfirmationComponent,
                            componentProps: {
                                appointment: appointment
                            },
                            cssClass: 'appointment-confirmation-modal'
                        })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (res) {
                            if (res.data && res.data.keepMakingNewAppointment) {
                                _this.modalController.dismiss();
                                _this.router.navigateByUrl('/tabs/new-appointment');
                            }
                            else {
                                _this.modalController.dismiss();
                            }
                        });
                        return [4 /*yield*/, modal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AppointmentNewComponent.prototype.intitializeData = function () {
        this.familyMemberMode = false;
        this.isReview = false;
        this.clientToSave = new Client();
        this.clientToSave.dateOfBirth = moment().format('YYYY-MM-DD');
        if (this.clientForm) {
            this.clientForm.resetForm(this.clientToSave);
        }
        this.appointmentToSave = new Appointment();
        this.appointmentToSave.client = this.clientToSave;
        this.appointmentToSave.familyMembers = [];
        this.loadHospitals();
    };
    return AppointmentNewComponent;
}());
export { AppointmentNewComponent };
