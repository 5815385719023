import { Observable, BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
// Parse
import { Parse } from 'parse';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
var User = /** @class */ (function () {
    function User() {
    }
    User.createFromParseObject = function (userObj) {
        var user = new User();
        user.id = userObj.id;
        user.name = userObj.get('username');
        user.email = userObj.get('email');
        user.phone = userObj.get('phone');
        user.firstName = userObj.get('firstName');
        user.middleName = userObj.get('middleName');
        user.lastName = userObj.get('lastName');
        user.lawFirmId = userObj.get('lawFirm') ? userObj.get('lawFirm').id : null;
        user.lawFirmName = userObj.get('lawFirm') ? userObj.get('lawFirm').get('name') : null;
        user.hospitalId = userObj.get('hospital')
            ? userObj.get('hospital').id
            : null;
        user.hospitalName = userObj.get('hospital')
            ? userObj.get('hospital').get('name')
            : null;
        user.hospitalNetworkId = userObj.get('providerNetwork')
            ? userObj.get('providerNetwork').id
            : null;
        user.hospitalNetworkName = userObj.get('providerNetwork')
            ? userObj.get('providerNetwork').get('name')
            : null;
        // user.hospitalNetwork = userObj.get('providerNetwork');
        user.isKavaUser = userObj.get('isKavaUser');
        user.lawFirmGroupId = userObj.get('lawFirmGroup') ? userObj.get('lawFirmGroup').id : null;
        user.notificationPref = userObj.get('notificationPref') || 'email';
        user.createdAt = moment(userObj.createdAt).format('LLL');
        return user;
    };
    return User;
}());
export { User };
var AuthProvider = /** @class */ (function () {
    function AuthProvider() {
        var _this = this;
        this.parseAppId = environment.parseAppId;
        this.parseJsKey = environment.parseJsKey;
        this.parseServerUrl = environment.parseServerUrl;
        this.currentUserSubject = new BehaviorSubject(null);
        this.parseInitialize();
        var isAuthenticated = this.currentUser() != null;
        if (isAuthenticated) {
            this.currentUserSubject.next(this.currentUser());
            if (this.currentUser().lawFirmGroupId) {
                var query = new Parse.Query(Parse.Object.extend('ManagingAggregator'));
                query.equalTo('objectId', this.currentUser().lawFirmGroupId);
                query.first().then(function (res) {
                    var u = _this.currentUser();
                    u.lawFirmGroupObject = res;
                    console.log('lawFirmGroupObject found', res);
                    _this.currentUserSubject.next(u);
                });
            }
        }
    }
    AuthProvider.prototype.getSessionTokenByPhoneNumber = function (phone) {
        return Parse.Cloud.run('getSessionTokenByPhoneNumber', { phone: phone });
    };
    AuthProvider.prototype.becomeUser = function (sessionToken) {
        var _this = this;
        console.log('becomeUser', sessionToken);
        return Parse.User.become(sessionToken).then(function (user) {
            _this.currentUserSubject.next(User.createFromParseObject(user));
            if (user.lawFirmGroupId) {
                var query = new Parse.Query(Parse.Object.extend('ManagingAggregator'));
                query.equalTo('objectId', _this.currentUser().lawFirmGroupId);
                query.first().then(function (res) {
                    var u = _this.currentUser();
                    u.lawFirmGroupObject = res;
                    console.log('lawFirmGroupObject found', res);
                    _this.currentUserSubject.next(u);
                });
            }
        });
    };
    AuthProvider.prototype.signin = function (username, password) {
        var _this = this;
        var self = this;
        return new Observable(function (observer) {
            Parse.User.logIn(username, password)
                .then(function (user) {
                console.log('User signed in successful with name: ' +
                    user.get('username') +
                    ' and email: ' +
                    user.get('email'));
                // if user has is not kava user, no lawfirm id, no hospital/network. force quit
                if (!user.get('isKavaUser') &&
                    user.get('lawFirm') == null &&
                    user.get('hospital') == null &&
                    user.get('providerNetwork') == null) {
                    console.error('no role assigned yet');
                    observer.error('No role assigned to you yet.');
                    self.currentUserSubject.next(null);
                    observer.complete();
                    return;
                }
                observer.next(true);
                var usr = User.createFromParseObject(user);
                console.log('user logged in', usr);
                self.currentUserSubject.next(usr);
                if (_this.currentUser().hospitalNetworkId) {
                    var query = new Parse.Query(Parse.Object.extend('ProviderNetwork'));
                    query.equalTo('objectId', _this.currentUser().hospitalNetworkId);
                    query.first().then(function (res) {
                        var u = _this.currentUser();
                        u.hospitalNetworkObject = res;
                        self.currentUserSubject.next(u);
                    });
                }
                if (_this.currentUser().lawFirmGroupId) {
                    var query = new Parse.Query(Parse.Object.extend('ManagingAggregator'));
                    query.equalTo('objectId', _this.currentUser().lawFirmGroupId);
                    query.first().then(function (res) {
                        var u = _this.currentUser();
                        u.lawFirmGroupObject = res;
                        console.log('lawFirmGroupObject found', res);
                        self.currentUserSubject.next(u);
                    });
                }
                observer.complete();
            })
                .catch(function (error) {
                console.error('Error: ' + error.code + ' ' + error.message);
                observer.error(error);
                self.currentUserSubject.next(null);
                observer.complete();
                return;
            });
        });
    };
    AuthProvider.prototype.signup = function (firstName, middleName, lastName, password, email, phone) {
        var self = this;
        return new Observable(function (observer) {
            var user = new Parse.User();
            user.set('firstName', firstName.trim().toLocaleLowerCase());
            user.set('middleName', middleName.trim().toLocaleLowerCase());
            user.set('lastName', lastName.trim().toLocaleLowerCase());
            user.set('username', email.trim().toLocaleLowerCase());
            user.set('password', password);
            user.set('email', email.trim().toLocaleLowerCase());
            user.set('phone', phone.trim().toLocaleLowerCase());
            user
                .signUp()
                .then(function (u) {
                console.log('User created successful with name: ' +
                    u.get('username') +
                    ' and email: ' +
                    u.get('email'));
                observer.next(true);
                self.currentUserSubject.next(User.createFromParseObject(u));
                observer.complete();
            })
                .catch(function (error) {
                console.log('Error: ' + error.code + ' ' + error.message);
                observer.error(error);
                observer.complete();
            });
        });
    };
    AuthProvider.prototype.signout = function () {
        var self = this;
        return new Observable(function (observer) {
            Parse.User.logOut().then(function () {
                observer.next(true);
                self.currentUserSubject.next(null);
            }, function (error) {
                console.log('lougout error:', error);
                observer.error(error); // todo: maybe only allow error no 209 (invalid session token)
                self.currentUserSubject.next(null);
            });
        });
    };
    Object.defineProperty(AuthProvider.prototype, "userState$", {
        get: function () {
            return this.currentUserSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    AuthProvider.prototype.currentUser$ = function () {
        return this.currentUserSubject.asObservable();
    };
    AuthProvider.prototype.currentUser = function () {
        var u = Parse.User.current();
        if (u) {
            return User.createFromParseObject(u);
        }
        return null;
    };
    AuthProvider.prototype.authenticated = function () {
        var isAuthenticated = this.currentUser() != null;
        // if (isAuthenticated) {
        //   this.currentUserSubject.next(this.currentUser());
        // }
        return isAuthenticated;
    };
    // public isAdmin(): boolean {
    //   return this.authenticated() && this.currentUser().isAdmin === true;
    // }
    AuthProvider.prototype.parseInitialize = function () {
        Parse.initialize(this.parseAppId, this.parseJsKey);
        Parse.serverURL = this.parseServerUrl;
    };
    AuthProvider.prototype.requestPasswordReset = function (email) {
        return Parse.User.requestPasswordReset(email);
    };
    AuthProvider.prototype.changePassword = function (newPassword) {
        var user = Parse.User.current();
        user.setPassword(newPassword);
        return user.save();
    };
    AuthProvider.prototype.changeUserProfile = function (userInfo) {
        var _this = this;
        var user = Parse.User.current();
        user.set('firstName', userInfo.firstName);
        user.set('middleName', userInfo.middleName);
        user.set('lastName', userInfo.lastName);
        user.set('email', userInfo.email.toLocaleLowerCase());
        user.set('username', userInfo.email.toLocaleLowerCase());
        user.set('phone', userInfo.phone);
        user.set('notificationPref', userInfo.notificationPref);
        return user
            .save()
            .then(function (u) { return _this.currentUserSubject.next(User.createFromParseObject(u)); });
    };
    AuthProvider.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthProvider_Factory() { return new AuthProvider(); }, token: AuthProvider, providedIn: "root" });
    return AuthProvider;
}());
export { AuthProvider };
