import * as moment from 'moment';
import { Hospital } from './hospital';

export class AppointmentListResponse {
  constructor(
    public totalCount: number = -1,
    public entries: Appointment[] = []
  ) { }
}

export enum AppointmentsDisplayMode {
  Upcoming = 0,
  Recent
}

export class Client {
  public canBeContacted: string; // familay member be directly contact or the guardian should receive all communications.
  public id: string;
  public firstName: string;
  public lastName: string;
  public phone: string;
  public phoneRipped: string;
  public dateOfBirth: string;
  public guardian?: Client;

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  static createFromParseObject(object: any) {
    if (object == null) {
      return null;
    }
    const result = new Client();
    result.id = object.id;
    result.firstName = object.get('firstName');
    result.lastName = object.get('lastName');
    result.phone = object.get('phone');
    if (result.phone) {
      result.phoneRipped = object.get('phone')
        .replace('(', '')
        .replace(')', '')
        .replace('-', '')
        .replace('', '');
    }
    result.dateOfBirth = object.get('dateOfBirth');
    result.canBeContacted = object.get('canBeContacted') ? 'true' : 'false';
    result.guardian = object.get('guardian') ? Client.createFromParseObject(object.get('guardian')) : null;

    return result;
  }
}

export class LawFirm {
  public id: string;
  public name: string;
  public address: string;

  static createFromParseObject(object: any) {
    const result = new LawFirm();
    result.id = object.id;
    result.name = object.get('name');
    result.address = `${object.get('street')} ${object.get(
      'city'
    )}, ${object.get('state')} ${object.get('zip')}`;

    return result;
  }
}

export enum AppointmentStatus {
  CheckedIn = 'checked-in',
  Cancelled = 'cancelled',
  Expired = 'expired'
}

export class Appointment {
  public id: string;
  public client: Client;
  // just use for to generate appoint for each client/family member, appointment object in parse doesn't have this field.
  public familyMembers: Client[];
  public hospital: Hospital;
  public lawFirm?: LawFirm;
  public createdAt: Date;
  // public updatedAt: Date;
  public status: string;
  public checkedInAt: Date;
  public cancelledAt: Date;

  get checkedInTime(): string {
    return moment(this.checkedInAt).format('LLL');
  }

  get cancellTime(): string {
    return moment(this.cancelledAt).format('LLL');
  }

  public get scheduledTimeString(): string {
    return moment(this.createdAt).format('MMM Do, YYYY');
  }

  public get hoursTillAutoCancel(): number {
    const hoursPassed = moment().diff(this.createdAt, 'hours');
    return 48 - hoursPassed;
  }

  static createFromParseObject(object: any) {
    const result = new Appointment();
    result.id = object.id;
    result.createdAt = object.createdAt;
    // result.updatedAt = object.updatedAt;
    result.checkedInAt = object.get('checkedInAt');
    result.status = object.get('status');

    result.client = Client.createFromParseObject(object.get('client'));

    result.hospital = Hospital.createFromParseObject(object.get('hospital'));
    result.lawFirm = Hospital.createFromParseObject(object.get('lawFirm'));

    return result;
  }
}
