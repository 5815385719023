<ion-app>
  <ion-split-pane contentId="menu-content" [when]="splitPane.getSplitPane()">
    <ion-menu contentId="menu-content">

      <ion-header>
        <ion-toolbar>

          <ion-buttons slot="start">
            <img src="../../../assets/images/logo-white-no-text.png" style="widows: 22px; height: 22px" />
          </ion-buttons>
          <ion-title>Kava Health</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <ion-list>

          <ng-container>
            <ion-item-divider>Profile</ion-item-divider>
            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerDirection]="'root'" routerLink="/tabs/profile">
                <ion-icon color="primary" slot="start" name="person"></ion-icon>
                <ion-label color="primary">
                  {{username}}
                </ion-label>

              </ion-item>


            </ion-menu-toggle>
            <ion-item-divider></ion-item-divider>
            <ion-menu-toggle auto-hide="false">
              <ion-item *ngIf="isLawFirmUser" routerLink="/tabs/new-appointment">
                <ion-icon color="primary" slot="start" name="add"></ion-icon>
                <ion-label color="primary">Schedule Client </ion-label>
              </ion-item>

            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false" *ngFor="let p of userPages">


              <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
                <ion-icon color="primary" slot="start" [name]="p.icon"></ion-icon>
                <ion-label color="primary">
                  {{ p.title }}
                </ion-label>

              </ion-item>


            </ion-menu-toggle>
            <ion-item-divider></ion-item-divider>
            <ion-item lines="none" class="ion-text-center">

              <ion-label [color]="isProd ? 'primary' : 'danger'" class="ion-text-wrap">
                <small>Version {{ appVersionNumber }}

                  <span *ngIf="!isProd">[UAT]</span>
                </small>
                <div *ngIf="newVersionAvailable"><ion-button (click)="reloadPage()">Reload Page</ion-button></div>

              </ion-label>

            </ion-item>
          </ng-container>


        </ion-list>
      </ion-content>

    </ion-menu>
    <ion-router-outlet id="menu-content">

    </ion-router-outlet>
  </ion-split-pane>


</ion-app>