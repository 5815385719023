import { Component, OnInit } from '@angular/core';
import { Appointment } from 'src/app/model/appointment';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-checkin-confirmation',
  templateUrl: './checkin-confirmation.component.html',
  styleUrls: ['./checkin-confirmation.component.scss']
})
export class CheckinConfirmationComponent implements OnInit {
  appointment: Appointment;

  constructor(
    public modalController: ModalController,
    private navParams: NavParams
  ) {
    this.appointment = this.navParams.data.appointment as Appointment;
  }

  ngOnInit() {}

  dismissModal(keepMakingNewAppointment) {
    this.modalController.dismiss({ keepMakingNewAppointment });
  }
}
